import React from 'react';
import CalendarSlot from './calendar_slot';
import CalendarHeader from './calendar_header';
import CalendarEmptyColumn from './calendar_empty_column'

import LoopIcon from '@material-ui/icons/Loop';
import { UserContext } from 'contexts/user_context';
import { CartContext } from 'contexts/cart_context';
import { AuthContext } from 'contexts/auth_context';
import CalorieBox from '../modify_calories/calorie_box';
import { RecipeBooksContext } from 'contexts/recipe_books_context';
import Popover from '@material-ui/core/Popover';
import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics';
import AssessmentOutlinedIcon from '@material-ui/icons/AssessmentOutlined';
import { Dialog } from '@material-ui/core';
import LogWeight from '../log_weight/log_weight';
import WeightOverTimeGraph from '../graphs/weight_over_time_graph';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const CalendarColumn = (props) => {
	const [generateAnchorEl, setGenerateAnchorEl] = React.useState(null)
	const getCalorieCategory = React.useContext(UserContext).getCalorieCategory();
	const generateDailyMeals = React.useContext(CartContext).generateDailyMeals
	const recipeList = React.useContext(RecipeBooksContext).recipeList

	const { currentUser } = React.useContext(AuthContext)
	const [isGenerateLoading, setIsGenerateLoading] = React.useState(false)
	const [wasGenerateTriggered, setWasGenerateTriggered] = React.useState(false)
	const [logWeightDialogOpen, setLogWeightDialogOpen] = React.useState(false)
	const [weightGraphDialogOpen, setWeightGraphDialogOpen] = React.useState(false)
    const history = useHistory()

	React.useEffect(() => {
		const handleGenerateTrigger = async () => {
			if (wasGenerateTriggered && recipeList.length !== 0) {
				await generateDailyMeals(props.calendarDate)
				setIsGenerateLoading(false)
				setWasGenerateTriggered(false)
			}
		};

		handleGenerateTrigger()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [recipeList, wasGenerateTriggered]);

	async function handleGenerateClick(event) {
		FirebaseAnalytics.logEvent({
			name: "clicked_plan_for_me",
			params: { value: '0.1', currency: 'USD', currentUser: currentUser ? currentUser.uid : "null" }
		})

		if (getCalorieCategory <= 5000 && getCalorieCategory >= 500) {

			setIsGenerateLoading(true)
			setWasGenerateTriggered(true)
		}
		else {
			setGenerateAnchorEl(event.currentTarget);
		}
	}

	function handleLogWeightClick() {
		setLogWeightDialogOpen(true)
	}

	const handleLogWeightExit = () => {
		setLogWeightDialogOpen(false)
	}

	const handleWeightGraphExit = () => {
		setWeightGraphDialogOpen(false)
	}

	const handleLogWeightSubmit = () => {
		setLogWeightDialogOpen(false)
		setWeightGraphDialogOpen(true)
	}

	const handleExitClick = () => {
		setGenerateAnchorEl(null)
	}


	if (!props.isColumnVisible) {
		return <div className="calendar-column"></div>
	}

	let categoryMeals = { "breakfast": [], "lunch": [], "dinner": [], "snack": [] }
	for (let cartMeal of props.cartMeals) {
		categoryMeals[cartMeal.calendarCategory].push(cartMeal)
	}

	let calendarDateObject = new Date(props.calendarDate)
	let isDayInFuture = true
	if (new Date() - calendarDateObject > 0) {
		isDayInFuture = false
	}

	let slots = []
	if (props.cartMeals.length === 0 && !props.isCarouselDisabled) {
		slots.push(
			<div style={{ textAlign: 'center', margin: '3px 0px 3px 0px', display: 'flex', alignItem: 'center', justifyContent: 'center' }}>
				<button className="generate-button" disabled={isGenerateLoading} onClick={handleGenerateClick} > Plan for me <LoopIcon style={isGenerateLoading ? { animation: 'spin 2s linear infinite', marginLeft: '2px', fontSize: '20px' } : { marginLeft: '2px', fontSize: '20px' }} /></button>
				{!isDayInFuture && <button className="log-weight-button" onClick={handleLogWeightClick}> <AssessmentOutlinedIcon style={{ marginRight: '5px', marginLeft: '-1px' }} /> Log Weight</button>}

			</div>
		)
		slots.push(<CalendarEmptyColumn key={`calendar-empty`} calendarDate={props.calendarDate} />)

	}
	else {

		for (let calendarCategory in categoryMeals) {
			if (categoryMeals[calendarCategory].length !== 0) {
				slots.push(<CalendarSlot key={`calendar-slot-${calendarCategory}`} cartMeals={categoryMeals[calendarCategory]} calendarDate={props.calendarDate} calendarCategory={calendarCategory} />)
			}
			else {
				slots.push(<CalendarSlot key={`calendar-slot-${calendarCategory}`} calendarDate={props.calendarDate} calendarCategory={calendarCategory} />)

			}
		}
		slots.push(
			!isDayInFuture && <div style={{ textAlign: 'center', margin: '14px 0px 8px 0px', display: 'flex', alignItem: 'center', justifyContent: 'center' }}>
				<button className="log-weight-button" onClick={handleLogWeightClick} ><div style={{display:'flex', alignItems:'center'}}>
					<div><AssessmentOutlinedIcon style={{ marginRight: '5px', marginLeft: '-1px' }} /></div> <div><div>Log Weight </div><div style={{fontSize:'10px', marginTop:'4px', color:'grey'}}>AI Diet Consultation </div></div>
				</div> </button>

			</div>
		)

	}

	return (<div className="calendar-column">
		<CalendarHeader day={props.day} calendarDate={props.calendarDate} cartMeals={props.cartMeals} />
		{window.innerWidth > 595 && slots.length === 1 ? <div style={{ height: '100%', padding: '0px 10px 0px 10px' }}>{slots}</div> :
			window.innerWidth > 595 && slots.length > 1 ? <>{slots}</> :
				<div style={{ overflowY: 'auto', padding: '0px 10px 0px 10px' }} > {slots}</div>}



		<Popover open={Boolean(generateAnchorEl)} anchorEl={generateAnchorEl} anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }} transformOrigin={{ vertical: 'top', horizontal: 'left', }} onClose={handleExitClick} >
			<div className="input-calories-popover">
				<div className="input-calories-popover-header">Input valid daily calories or macros to generate</div>
				<CalorieBox disableRecommendation={true} />
			</div>
		</Popover>

		<Dialog open={logWeightDialogOpen} maxWidth={'xl'} onClose={handleLogWeightExit} >
			<LogWeight calendarDate={props.calendarDate} handleLogWeightExit={handleLogWeightSubmit}></LogWeight>
		</Dialog>

		<Dialog open={weightGraphDialogOpen} maxWidth={'xl'} onClose={handleWeightGraphExit} >
			<div style={{ width: '350px', maxWidth: 'calc(100vw - 20px)', padding: '10px' }}>
				<div className="input-calories-popover-header" style={{ fontFamily: 'Montserrat-SemiBold' }}>Your weight over time</div>

				<WeightOverTimeGraph />
				<div style={{fontSize:'12px', color:'grey', textAlign:'center', marginTop:'15px', marginBottom:'15px'}}>Need help adjusting macros & diet plan?</div>
				<button className="generate-button" style={{margin:'10px auto'}} onClick={()=>{history.push('ai_consultation')}}>Consult with AI</button>

			</div>
		</Dialog>

	</div>)
}

export default CalendarColumn;  