import React, { useContext } from 'react';
import { useMultiDrag } from 'react-dnd-multi-backend';
import { ItemTypes } from 'components/shared/item_types/item_types';
import { CartContext } from 'contexts/cart_context';
import Button from '@material-ui/core/Button';
// import Tooltip from '@material-ui/core/Tooltip';
import CaloriesBadge from 'images/calories_badge.png'
import ProteinBadge from 'images/protein_badge.png'
import CarbsBadge from 'images/carbs_badge.png'
import FatBadge from 'images/fat_badge.png'


import { CloseRounded } from '@material-ui/icons';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faThumbtack } from '@fortawesome/free-solid-svg-icons'
import MealDetailsCard from '../meal_details_card/meal_details_card';
import { Dialog, Tooltip } from '@material-ui/core';
import { decimalToFraction, getMealNutrientAmount } from 'helpers/recipe_helpers';
import MealPortioningSlider from '../meal_portioning_slider/meal_portioning_slider';
import _ from 'lodash';
import ContentCopy from 'images/content_copy.svg'



const CalendarMeal = (props) => {
    const [[dragProps, drag]] = useMultiDrag({
        type: ItemTypes.MEAL,
        item: { meal: props.cartMeal, type: ItemTypes.MEAL },
        collect: (monitor) => ({
            isDragging: !!monitor.isDragging(),
        }),
    });

    const deleteMeal = useContext(CartContext).deleteMeal;
    // const togglePinMeal = useContext(CartContext).togglePinMeal;
    const setCartMeal = React.useContext(CartContext).setCartMeal
    const duplicateMeal = useContext(CartContext).duplicateMeal;
    const getMealAttributeWithOptions = useContext(CartContext).getMealAttributeWithOptions
    const [isDialogOpen, setIsDialogOpen] = React.useState(false)
    const [portion, setPortion] = React.useState(props.cartMeal.portion ? props.cartMeal.portion : 1)

    function handleDeleteClick(event) {
        deleteMeal(props.cartMeal)
        event.stopPropagation()
    }

    // function handleLockClick() {
    //     togglePinMeal(props.cartMeal.key)
    // }

    function handleDuplicateClick() {
        duplicateMeal(props.cartMeal)
    }

    function handleDialogOpen(event) {
        if (event.target.tagName !== 'SPAN' && event.target.id !== "calendar-meal-duplicate") {
            setIsDialogOpen(true);
        }

    }

    function handleDialogClose() {
        setIsDialogOpen(false);
    }




    const setMealAndRecalculateCalories = (meal) => {
        for (let mealNutrient of meal.nutrition.nutrients) {
            mealNutrient['amount'] = 0
            for (let ingredient of meal.ingredients) {
                for (let ingredientNutrient of ingredient.nutrition.nutrients) {
                    if (mealNutrient.name === ingredientNutrient.name) {
                        if (ingredientNutrient['amount'] && ingredient['amount']) {
                            mealNutrient['amount'] = mealNutrient['amount'] + (ingredientNutrient['amount'] * ingredient['amount'])
                        }
                    }
                }
            }
        }
        setCartMeal(meal)

    }

    React.useEffect(() => {
        if (portion !== props.cartMeal.portion) {
            setPortion(props.cartMeal.portion)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.cartMeal])


    React.useEffect(() => {
        const updatePortion = setTimeout(() => {
            if (props.cartMeal.portion === portion) {
                return
            }

            //first portion ingredients, then set the new cart meal
            let mealClone = _.cloneDeep(props.cartMeal)

            if (mealClone.areNutrientsManual) {
                //with manual nutrients
                for (let mealNutrient of mealClone.nutrition.nutrients) {
                    if (mealNutrient.name === "Calories") { mealNutrient.amount = Math.round(mealNutrient.amount * portion / props.cartMeal.portion * 10) / 10 }
                    if (mealNutrient.name === "Fat") { mealNutrient.amount = Math.round(mealNutrient.amount * portion / props.cartMeal.portion * 10) / 10 }
                    if (mealNutrient.name === "Carbohydrates") { mealNutrient.amount = Math.round(mealNutrient.amount * portion / props.cartMeal.portion * 10) / 10 }
                    if (mealNutrient.name === "Protein") { mealNutrient.amount = Math.round(mealNutrient.amount * portion / props.cartMeal.portion * 10) / 10 }
                }
                setCartMeal({ ...mealClone, portion: portion })

            }
            else {
                //with ingredients
                if (props.cartMeal.portion) {
                    for (let ingredient of mealClone.ingredients) {
                        ingredient.amountString = decimalToFraction(ingredient.amount * portion / props.cartMeal.portion)
                        ingredient.amount = ingredient.amount * portion / props.cartMeal.portion
                    }
                }
                else {
                    for (let ingredient of mealClone.ingredients) {
                        ingredient.amountString = decimalToFraction(ingredient.amount * portion)
                        ingredient.amount = ingredient.amount * portion
                    }
                }
                setMealAndRecalculateCalories({ ...mealClone, portion: portion })
            }


        }, 100);
        return () => clearTimeout(updatePortion);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [portion])

    let cals = getMealNutrientAmount(props.cartMeal, "Calories")

    return (
        <>
        
            <div style={{display:'flex'}}>
                <div className="calendar-meal" ref={drag} style={{
                    opacity: dragProps.isDragging ? '0.1' : '1',
                    cursor: dragProps.isDragging ? 'grabbing' : 'grab',
                }}>
                    <Button className='calendar-meal-delete-button' onClick={handleDeleteClick}><CloseRounded fontSize="small" /></Button>

                    <div className="calendar-meal-top" >
                        <div className="calendar-meal-left" onClick={handleDialogOpen}   >
                            <img className="calendar-meal-thumbnail" src={props.cartMeal.picture} alt={props.cartMeal.name} />

                            <div className="calendar-meal-text">
                                <div className="calendar-meal-label" style={{ zIndex: '3000' }} onClick={handleDialogOpen} >  {getMealAttributeWithOptions(props.cartMeal, "name")}</div>
                                <div style={{ display: 'flex' }}>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        {cals > 0 && <>
                                            <div style={{ fontSize: cals > 1000 ? '10px' : '11px' }} className="calendar-meal-calorie-label"> {getMealNutrientAmount(props.cartMeal, "Calories")}
                                                <img src={CaloriesBadge} alt="calorie badge" style={{ width: cals > 1000 ? '12px' : '14px', marginLeft: '3px' }} /></div>

                                            <div style={{ fontSize: cals > 1000 ? '10px' : '11px' }} className="calendar-meal-calorie-label"> {getMealNutrientAmount(props.cartMeal, "Protein")}g
                                                <img src={ProteinBadge} alt="protein badge" style={{ width: cals > 1000 ? '12px' : '14px', marginLeft: '3px' }} /></div>

                                            <div style={{ fontSize: cals > 1000 ? '10px' : '11px' }} className="calendar-meal-calorie-label"> {getMealNutrientAmount(props.cartMeal, "Carbohydrates")}g
                                                <img src={CarbsBadge} alt="carbs badge" style={{ width: cals > 1000 ? '12px' : '14px', marginLeft: '3px' }} /></div>

                                            <div style={{ fontSize: cals > 1000 ? '10px' : '11px' }} className="calendar-meal-calorie-label"> {getMealNutrientAmount(props.cartMeal, "Fat")}g
                                                <img src={FatBadge} alt="fat badge" style={{ width: cals > 1000 ? '12px' : '14px', marginLeft: '3px' }} /></div>
                                        </>}

                                    </div>
                                    {/* <div style={{ marginLeft: 'auto', marginTop: '-10px', marginBottom: '10px', marginRight: '3px' }}>
                                        <MealPortioningSlider portion={portion} setPortion={setPortion} shrink={true} />
                                    </div> */}
                                </div>

                            </div>
                        </div>
                        {/* <Tooltip title="Pin meal to keep it when regenerating days">
                        <Button className='pin-button' onClick={handleLockClick}>{props.cartMeal.pinned ? <FontAwesomeIcon icon={faThumbtack} className="enabled-pin" /> : <FontAwesomeIcon icon={faThumbtack} className="disabled-pin" />}</Button>
                    </Tooltip> */}

                    </div>


                    <Tooltip title="Duplicate meal">
                        <Button className='duplicate-button' onClick={handleDuplicateClick}><img id="calendar-meal-duplicate" className="landing-upsell-image" src={ContentCopy} alt={"content copy"} style={{ height: '18px', opacity: '0.4', marginRight: '-2px' }} /></Button>
                    </Tooltip>
                    </div>

                </div>
            {isDialogOpen &&

                <Dialog maxWidth={'xl'} open={isDialogOpen} onClose={handleDialogClose}>
                    <MealDetailsCard isReadOnly={false} handleExitClick={handleDialogClose} meal={props.cartMeal} portion={portion} setPortion={setPortion}></MealDetailsCard>
                </Dialog>
            }


        </>
    )
}

export default CalendarMeal;