import './navigation.css'

import React from 'react';
// import Badge from '@material-ui/core/Badge';
// import IconButton from '@material-ui/core/IconButton';
// import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import PersonIcon from '@material-ui/icons/Person';
import { NavLink } from 'react-router-dom';
import { CartContext } from 'contexts/cart_context';
import { useLocation } from 'react-router-dom';
import { Popover } from '@material-ui/core';
import NavigationCartHover from './navigation_cart_hover';
import { AuthContext } from 'contexts/auth_context';

import { Capacitor } from '@capacitor/core';

import LogoWide from 'images/image-2.png'
import RecipesIcon from 'images/recipes-icon.png'
import CalendarIcon from 'images/calendar-icon.png'
import LogoNarrow from 'images/BiteTracker-App-Icon.png'
import SettingsSidebar from '../settings_sidebar/settings_sidebar';



const Navigation = () => {
   const cartObject = React.useContext(CartContext).cartObject;
   const [cartHoveredAnchorEl, setCartHoveredAnchorEl] = React.useState(null)
   const [accountDialogOpenAnchorEl, setAccountDialogOpenAnchorEl] = React.useState(null)
   const location = useLocation()
   const { currentUser } = React.useContext(AuthContext)

   const [windowSize, setWindowSize] = React.useState([
      window.innerWidth,
      window.innerHeight,
   ]);


   React.useEffect(() => {
      const handleWindowResize = () => {
         setWindowSize([window.innerWidth, window.innerHeight]);
      };

      window.addEventListener('resize', handleWindowResize);

      return () => {
         window.removeEventListener('resize', handleWindowResize);
      };
   }, []);



   // function handleCartHover(event) {
   //    setCartHoveredAnchorEl(event.currentTarget);
   // }

   function handleCartHoverExit() {
      setCartHoveredAnchorEl(null);
   }

   function openAccountPopover(event) {
      setAccountDialogOpenAnchorEl(event.currentTarget)
   }

   function exitAccountPopover() {
      setAccountDialogOpenAnchorEl(null);
   }




   let mealCounter = 0
   for (let meal in cartObject) {
      if (cartObject[meal].type === "ready-made") {
         mealCounter += 1
      }
   }
   if (location.pathname === "/login" || location.pathname === "/signup") {
      if (Capacitor.getPlatform() !== "web") {
         return (<></>)
      }
   }

   if (["/", "/landing", "/home"].includes(location.pathname)) {
      return (<></>)
   }

   let navigation = <></>

   if (Capacitor.getPlatform() !== "web" && currentUser) {
      navigation = <div className="navigation-app">
         <div className="navigation-app-section">
            {currentUser && <NavLink className="navigation-app-link" to="/planning"><img alt="recipes" className={location.pathname === "/planning" ? "navigation-app-calendar-image" : "navigation-app-calendar-image-inactive"} src={RecipesIcon} /> {location.pathname === "/planning" ? 'Planning' : "Planning"}</NavLink>}
         </div>

         <div className="navigation-app-section"><NavLink className="navigation-app-link" to="/calendar"><img alt="calendar" className={location.pathname === "/calendar" ? "navigation-app-calendar-image" : "navigation-app-calendar-image-inactive"} src={CalendarIcon} /> {location.pathname === "/calendar" ? 'Tracking' : "Tracking"}</NavLink>
         </div>


         {/* <div className="navigation-app-section">
            {currentUser && <NavLink className="navigation-app-link" to="/order_ingredients">
               <Badge badgeContent={ingredientCounter} max={9} color="error" style={{
                  background: '0px 0px'
               }}>
                  <img style={{ width: '20px', marginBottom: '2px', marginTop: '1px' }} alt="order ingredients" className={location.pathname === "/order_ingredients" ? "navigation-app-calendar-image" : "navigation-app-calendar-image-inactive"} src={IngredientCartIcon} />
               </Badge>
               {location.pathname === "/order_ingredients" ? 'Order' : "Order"}</NavLink>}


         </div> */}

         {/* <NavLink className="navigation-cart" to="/cart" onMouseEnter={handleCartHover} onMouseLeave={handleCartHoverExit}>
         <IconButton aria-label="cart">
            <Badge badgeContent={mealCounter} color="error" style={{
               padding: '3px 0px'
            }}>
               <ShoppingCartIcon />
            </Badge>
            &nbsp;{location.pathname === "/cart" ? <b>CART</b> : "CART"}&nbsp;
         </IconButton>

      </NavLink> */}
      </div>

   }

   else {
      navigation = <div className="navigation">
         <NavLink className="navigation-link" to={currentUser ? "/calendar" : "/"}>
            {windowSize[0] > 500 ? <img style={{ marginTop: '5px' }} className="navigation-logo" alt="logo" src={LogoWide} /> :
               <img style={{ marginTop: '5px' }} className="navigation-logo-mobile" alt="logo" src={LogoNarrow} />
            }
         </NavLink>
         <div className="navigation-right">
            {currentUser && <NavLink className="navigation-link" to="/planning"><img alt="recipes" className="navigation-calendar-image" src={RecipesIcon} /> {location.pathname === "/planning" ? <b>Planning</b> : "Planning"}</NavLink>}

            {currentUser && <NavLink className="navigation-link" to="/calendar"><img alt="calendar" className="navigation-calendar-image" src={CalendarIcon} /> {location.pathname === "/calendar" ? <b>Tracking</b> : "Tracking"}</NavLink>}


            {currentUser ? <button className="navigation-button" onClick={openAccountPopover} > <><PersonIcon style={{ color: '#2D7933', marginBottom: '1px', marginRight: '3px', fontSize: '27px' }} />
               {currentUser.displayName && windowSize[0] > 500 ? currentUser.displayName.split(" ")[0] : ""}</></button> : <NavLink className="navigation-link" to="/account">{location.pathname === "/account" ? <b>Sign in</b> : "Login"}</NavLink>}

            {/* <NavLink className="navigation-cart" to="/cart" onMouseEnter={handleCartHover} onMouseLeave={handleCartHoverExit}>
            <IconButton aria-label="cart">
               <Badge badgeContent={mealCounter} color="error" style={{
                  padding: '3px 0px'
               }}>
                  <ShoppingCartIcon />
               </Badge>
               &nbsp;{location.pathname === "/cart" ? <b>CART</b> : "CART"}&nbsp;
            </IconButton>

         </NavLink> */}
         </div>

      </div>
   }

   return (<> {navigation}

      {cartHoveredAnchorEl && mealCounter > 0 &&
         <Popover style={{ pointerEvents: 'none' }} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} transformOrigin={{ vertical: 'top', horizontal: 'right', }}
            id="mouse-over-popover" open={Boolean(cartHoveredAnchorEl)} anchorEl={cartHoveredAnchorEl} onClose={handleCartHoverExit} disableRestoreFocus>
            <NavigationCartHover />
         </Popover>
      }


      <SettingsSidebar open={accountDialogOpenAnchorEl} close={exitAccountPopover} />


   </>
   );
}

export default Navigation;