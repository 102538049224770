import './meal_details_card.css'

import React from "react";
import { Button, Dialog, MenuItem, Select, TextField } from '@material-ui/core';
import { CartContext } from 'contexts/cart_context';
import { CloseRounded } from '@material-ui/icons';
import { getMealNutrientAmount } from 'helpers/recipe_helpers';
import ModifyRecipeIngredients from '../modify_recipe/modify_recipe_ingredients';
import { UserContext } from 'contexts/user_context';
import ModifyRecipeInstructions from '../modify_recipe/modify_recipe_instructions';
import MealPortioningSlider from '../meal_portioning_slider/meal_portioning_slider';
import CalorieChart from '../calorie_chart/calorie_chart';
import BookmarkBorderIcon from '@material-ui/icons/BookmarkBorder';
import CalendarIcon from 'images/calendar-icon.png'
import RecipesIcon from 'images/recipes-icon.png'
import EditIcon from '@material-ui/icons/Edit';
import { RecipeBooksContext } from 'contexts/recipe_books_context';
import ContentCopy from 'images/content_copy.svg'
import ClockIcon from 'images/clock_ic.svg'
import CopyRecipeDialog from '../modify_recipe/copy_recipe_dialog';
import { useHistory } from "react-router-dom"
import FavoriteIcon from '@material-ui/icons/Favorite';
import _ from 'lodash';
import SignalCellular1BarIcon from '@material-ui/icons/SignalCellular1Bar';
import DeleteIcon from '@material-ui/icons/Delete';

const MealDetailsCard = (props) => {
    const addOrMoveMealToColumn = React.useContext(CartContext).addOrMoveMealToColumn;
    const [optionsChosen, setOptionsChosen] = React.useState(props.meal ? props.meal.optionsChosen ? props.meal.optionsChosen : {} : {})
    const getMealAttributeWithOptions = React.useContext(CartContext).getMealAttributeWithOptions
    const triggerCartStateRefresh = React.useContext(CartContext).triggerCartStateRefresh
    const setCartMeal = React.useContext(CartContext).setCartMeal
    const updateCookingRecipe = React.useContext(CartContext).updateCookingRecipe
    const userObject = React.useContext(UserContext).userObject
    const [mealName, setMealName] = React.useState(props.meal ? props.meal.name : '')
    const [attemptedCopy, setAttemptedCopy] = React.useState(false)
    const [attemptedFavorite, setAttemptedFavorite] = React.useState(false)
    const addFavoriteCustomLog = React.useContext(CartContext).addFavoriteCustomLog;
    const removeMeal = React.useContext(CartContext).deleteMeal;
    const [attemptedDeletion, setAttemptedDeletion] = React.useState(false)
    const deleteRecipe = React.useContext(RecipeBooksContext).deleteRecipe
    const portion = props.portion
    const setPortion = props.setPortion
    const getCustomRecipeBookId = React.useContext(RecipeBooksContext).getCustomRecipeBookId
    const history = useHistory()


    const setMeal = props?.meal?.cooking_recipe_id ? props.setAIRecipe ? props.setAIRecipe : updateCookingRecipe : setCartMeal

    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2
    })

    React.useEffect(() => {
        const updateName = setTimeout(() => {
            if (!props.isReadOnly) { setMeal({ ...props.meal, name: mealName }) }

        }, 200);
        return () => clearTimeout(updateName);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mealName])


    function handleDeleteClick(event) {
        removeMeal(props.meal)
        event.stopPropagation()
        props.handleExitClick()

    }

    function handleMealNameChange(e) {
        setMealName(e.target.value)
    }

    function navigateToRecipes() {
        history.push("/planning")
        props.handleExitClick()

    }


    function handleAddToCartClick() {
        if (props.customAddMealFunction) {
            props.customAddMealFunction(props.meal)
            props.handleExitClick()
        }

        else {
            let date = new Date(new Date(userObject.calendarFirstDate).getTime())

            if (userObject.calendarPosition || userObject.calendarPosition === 0) {
                date.setDate(date.getDate() + userObject.calendarPosition - 30);
            }

            addOrMoveMealToColumn(date, { ...props.meal, optionsChosen: optionsChosen, id: undefined })
            props.handleExitClick()
        }


    }

    function handleOptionChange(e) {
        e.preventDefault()
        let optionCategoryIndex = parseInt(e.currentTarget.getAttribute('optionCategoryIndex'))
        let optionListItemIndex = parseInt(e.currentTarget.getAttribute('optionListItemIndex'))
        //here we want to also modify the options of the cart meal
        if (props.meal.options[optionCategoryIndex]) {
            props.meal.optionsChosen = { ...optionsChosen, [e.target.name]: props.meal.options[optionCategoryIndex].optionList[optionListItemIndex] }
            setOptionsChosen({ ...optionsChosen, [e.target.name]: props.meal.options[optionCategoryIndex].optionList[optionListItemIndex] });
        }
        else {
            props.meal.optionsChosen = { ...optionsChosen, [e.target.name]: null }
            setOptionsChosen({ ...optionsChosen, [e.target.name]: null });
        }

        if (!props.addToCartEnabled) {
            //here we want to trigger a state update of the cart to re-render other components
            triggerCartStateRefresh()

        }
    }

    const handleAttemptedCopy = () => {
        setAttemptedCopy(true)
    }

    const handleAttemptedFavorite = () => {
        setAttemptedFavorite(true)
    }

    const handleAddToFavorite = () => {
        let mealObject = _.cloneDeep(props.meal)
        delete mealObject['calendarDate'];
        delete mealObject['id'];
        delete mealObject['calendarCategory'];
        addFavoriteCustomLog(mealObject, true)
        props.handleExitClick()

    }

    const handleAttemptedDeletion = () => {
        setAttemptedDeletion(true)
    }


    async function handleDelete() {
        setAttemptedDeletion(false)
        deleteRecipe(props.meal)
        props.handleExitClick()
    }

    let tags = []
    if (!props.meal) {
        return <></>
    }

    for (let tagIndex in props.meal.tags) {
        tags.push(<div key={`tags-${props.meal.tags[tagIndex]}`} className="meal-hover-card-info-tag">{props.meal.tags[tagIndex]}</div>)
    }

    let options = []

    for (let optionCategoryIndex in props.meal.options) {
        let optionCategory = props.meal.options[optionCategoryIndex]
        options.push(<span style={{ padding: '3px 5px' }}>{optionCategory.optionCategory}:</span>)
        let innerOptions = [<MenuItem value={null} key={null}>None</MenuItem>]
        for (let optionListItemIndex in optionCategory.optionList) {
            let optionListItem = optionCategory.optionList[optionListItemIndex]
            innerOptions.push(<MenuItem optionListItemIndex={optionListItemIndex} optionCategoryIndex={optionCategoryIndex} value={optionListItem.optionName} key={optionListItem.optionName}>
                {optionListItem.optionName + " (+$" + optionListItem.priceToAdd + ")"}</MenuItem>)
        }
        options.push(<div>
            <Select onChange={handleOptionChange} value={optionsChosen[optionCategory.optionCategory] ? optionsChosen[optionCategory.optionCategory].optionName : null} name={optionCategory.optionCategory}>
                {innerOptions}
            </Select>
        </div>)
    }

    return (<div className="meal-hover-card-frame">
        <div className="meal-hover-card">
            <Button className='modify-recipe-exit-button' onClick={props.handleExitClick}><CloseRounded fontSize="small" /></Button>

            <div className="meal-hover-card-header">
                <TextField multiline className="modify-recipe-name" name="name" value={mealName} onChange={handleMealNameChange} InputProps={{
                    disableUnderline: props.isReadOnly, readOnly: props.isReadOnly, inputProps: { style: { textAlign: "center", fontSize: '16px', padding: mealName.length / 6 + 'px', lineHeight: '24px' }, }
                }}> </TextField>
                <div className="meal-hover-card-header-description">{props.meal.description}</div>
                <div className="meal-hover-card-info-tags">{tags}</div>
                <img className="meal-hover-card-picture" style={{ marginBottom: '5px' }} src={props.meal.picture} alt={props.meal.name} />
                {(props.meal.complexity || !!props.meal.readyInMinutes) && <div style={{ marginBottom: '14px', marginTop: '8px', color: 'grey', fontSize: '13px', fontFamily: 'Montserrat-SemiBold', display: 'flex' }}>
                    {(!!props.meal.readyInMinutes) &&
                        <div style={{ display: 'flex', alignItems: 'center', marginRight: '11px', marginLeft: '11px' }}><img alt="clock icon" style={{ marginRight: '8px', width: '21px' }} src={ClockIcon}></img> {props.meal.readyInMinutes} minutes</div>
                    }
                    {props.meal.complexity &&
                        <div style={{ display: 'flex', alignItems: 'center', marginRight: '11px', marginLeft: '11px', textTransform: 'capitalize' }}>
                            <SignalCellular1BarIcon style={{ marginRight: '8px', color: "rgb(45, 121, 51)" }} /> {props.meal.complexity}</div>
                    }
                </div>
                }
                {!props.disableCopy && <div style={{ marginBottom: '14px', marginTop: '12px' }}>
                    {!props.meal.areNutrientsManual ? <Button className="modify-recipe-top-button" variant="outlined" onClick={handleAttemptedCopy}>
                        {props.meal.recipeBooks && props.meal.recipeBooks[0] === getCustomRecipeBookId() && !props.meal.calendarDate ?
                            <><img src={ContentCopy} alt='copy button' style={{ marginRight: '5px', filter: 'invert(38%) sepia(10%) saturate(2766%) hue-rotate(74deg) brightness(98%) contrast(90%)' }} /> Copy this recipe</> :
                            <><BookmarkBorderIcon style={{ marginRight: '8px', color: "rgb(45, 121, 51)" }} /> Save this recipe</>
                        }
                    </Button> : <Button className="modify-recipe-top-button" variant="outlined" onClick={handleAttemptedFavorite}>
                        <FavoriteIcon style={{ marginRight: '8px', color: '#ff7c93' }} /> Add to favorites

                    </Button>
                    }

                    {props.addToCalendarEnabled && <><Button style={{ marginLeft: '10px' }} onClick={handleAddToCartClick} variant="outlined" className="calendar-add-to-cart-top-button"><img alt="calendar" style={{ marginRight: '10px', height: '20px', padding: '2px' }} src={CalendarIcon} />Add to calendar</Button><br></br></>}
                    {props.addToPlanEnabled && <><Button style={{ marginLeft: '10px' }} onClick={handleAddToCartClick} variant="outlined" className="calendar-add-to-cart-top-button"><img alt="calendar" style={{ marginRight: '10px', height: '20px', padding: '2px' }} src={RecipesIcon} />Add to plan</Button><br></br></>}


                    {props.meal.recipeBooks && props.handleEditRecipeClickInDetailsCard && props.meal.recipeBooks[0] === getCustomRecipeBookId() && <div style={{ marginTop: '12px' }}>
                        <Button style={{ marginLeft: '10px' }} onClick={() => { props.handleEditRecipeClickInDetailsCard(props.meal) }} variant="outlined" className="calendar-add-to-cart-top-button"><EditIcon style={{ marginRight: '5px' }} />Edit Recipe</Button>
                        <Button style={{ marginLeft: '10px' }} onClick={handleAttemptedDeletion} variant="outlined" className="calendar-add-to-cart-top-button"><DeleteIcon style={{ marginRight: '5px' }} /> Delete Recipe</Button>

                    </div>}


                    {props.meal.calendarDate && !props.addToCalendarEnabled && <><Button style={{ marginLeft: '10px' }} onClick={handleDeleteClick} variant="outlined" className="calendar-add-to-cart-top-button"><DeleteIcon style={{ marginRight: '8px' }} />Remove</Button><br></br></>}

                </div>

                }
                {!props.isReadOnly && <> 
                    <div style={{ textAlign: 'center', fontFamily: 'Montserrat-SemiBold', padding: '5p 0px 0px 0px' }}>Choose Servings</div>
                <MealPortioningSlider portion={portion} setPortion={setPortion} meal={props.meal} setCartMeal={setMeal} index={props.index} /><br></br></>}

            </div>
            <div className="meal-hover-card-nutrients" style={{ margin: '0px 5px' }}>
                <CalorieChart meal={props.meal} setRecipe={setMeal}></CalorieChart>
            </div>

            <div className="meal-hover-card-info">
                {getMealNutrientAmount(props.meal, "Calories") > 0 && props.meal.price ? <div className="meal-hover-card-top-info"> {getMealAttributeWithOptions(props.meal, "calories")} Cals | {formatter.format(getMealAttributeWithOptions(props.meal, "price"))}  </div> :
                    getMealNutrientAmount(props.meal, "Calories") > 0 ? <></> :
                        props.meal.price && <div className="meal-hover-card-top-info">{formatter.format(props.meal.price)} </div>}

                {!props.meal.areNutrientsManual && <ModifyRecipeIngredients isReadOnly={props.isReadOnly} recipe={props.meal} setRecipe={setMeal} disableSearch={props.isReadOnly} />}

                <div className="meal-hover-card-options">{options}</div>


            </div>

            {!props.meal.areNutrientsManual &&
                <div className="meal-hover-card-info">
                    <ModifyRecipeInstructions isReadOnly={true} recipe={props.meal} setRecipe={setMeal} />

                </div>
            }

            {props.addToCalendarEnabled && <><button onClick={handleAddToCartClick} className="calendar-add-menu-meal-button">Add to calendar</button><br></br></>}


        </div>
        <CopyRecipeDialog scrollToTopOfRecipeBookList={navigateToRecipes} attemptedCopy={attemptedCopy} prepareWebRecipe={props.prepareWebRecipe} setAttemptedCopy={setAttemptedCopy} close={props.close} recipe={props.meal} setCreatingNewRecipe={props.setCreatingNewRecipe} setRecipeBookBeingModified={props.setRecipeBookBeingModified} />
        <Dialog maxWidth={'xl'} open={Boolean(attemptedFavorite)} >
            <div style={{ textAlign: 'center', margin: '30px' }}> Confirm copy
                <div style={{ color: 'grey', fontSize: '14px', margin: '10px 0px 12px 0px' }}>This action will add this item into your food tracking favorites for easier access</div>
                <div><button className="calendar-add-menu-meal-button" onClick={handleAddToFavorite}>Favorite</button> <button className="modify-recipe-cancel-button" onClick={() => { setAttemptedFavorite(false) }}>Cancel</button> </div>
            </div>

        </Dialog>

        <Dialog maxWidth={'xl'} open={Boolean(attemptedDeletion)} >
            <div style={{ textAlign: 'center', margin: '30px' }}> Are you sure you want to delete this recipe?
                <div style={{ color: 'grey', fontSize: '14px', margin: '10px 0px 0px 0px' }}>This action will delete the recipe from this recipe book. You cannot undo this.</div>
                <div><Button className="modify-recipe-delete-button" onClick={handleDelete}>Remove</Button> <Button className="modify-recipe-cancel-button" onClick={() => { setAttemptedDeletion(false) }}>Cancel</Button> </div>
            </div>

        </Dialog>


    </div>)

}

export default MealDetailsCard;