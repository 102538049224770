import React, { createContext, useContext, useState } from 'react'
// import axios from 'axios';
import { getFirestore, collection, query, doc, where, getDocs, setDoc, documentId, writeBatch, orderBy } from "firebase/firestore";
import { v4 as uuidv4 } from 'uuid';

import { UserContext } from './user_context';
import { AuthContext } from './auth_context';
// import { AuthContext } from './auth_context';
import axios from 'axios'
// import { config } from 'Constants';
import _ from 'lodash';
import { config } from 'Constants';

// import { getDateMonthYear } from 'helpers/date_helpers';


// import { collection, query, where, getDocs } from "firebase/firestore";




export const RecipeBooksContext = createContext();

//cartObject is like [{name:"Salmon",pinned:true,calendarPositionX:0,calendarCategory:snack}]

export const RecipeBooksContextProvider = (props) => {
    const [recipeBookList, setRecipeBookList] = useState([]);
    const [recipeList, setRecipeList] = useState([]);
    const userObject = useContext(UserContext).userObject

    //this code is done to regenerate the recipe list for the backend
    //  let recipeListClone=[]
    //  for(let item of recipeList){
    //      for(let nutrient of item.nutrition.nutrients){
    //         if (nutrient.name === "Calories"){
    //              item.calories=nutrient.amount
    //          }
    //          if (nutrient.name === "Fat"){
    //             item.fat=nutrient.amount
    //         }
    //         if (nutrient.name === "Carbohydrates"){
    //             item.carbs=nutrient.amount
    //         }
    //         if (nutrient.name === "Protein"){
    //             item.protein=nutrient.amount
    //         }
    //      }
    //      recipeListClone.push({name:item.name, calories:item.calories, protein:item.protein, fat:item.fat, carbs:item.carbs, id:item.id, portion:1, categories:item.categories, tags: item.tags})
    //  }
    //  console.log(recipeListClone)


    //this code is for removing nutrients not needed
    // let newRecipeList = []
    // for (let item of recipeList) {
    //     let recipeClone = _.cloneDeep(item)
    //     let nutrients = []
    //     for (let nutrient of item.nutrition.nutrients) {
    //         if (["Calories", "Fat","Saturated Fat", "Carbohydrates", "Net Carbohydrates", "Sugar","Cholesterol","Sodium","Protein","Fiber"].includes(nutrient.name)) {
    //             nutrients.push(nutrient)
    //         }
    //     }
    //     for(let ingredient of recipeClone.ingredients){
    //         let ingredientNutrients = []
    //         for (let ingredientNutrient of ingredient.nutrition.nutrients) {
    //             if (["Calories", "Fat","Saturated Fat", "Carbohydrates", "Net Carbohydrates", "Sugar","Cholesterol","Sodium","Protein","Fiber"].includes(ingredientNutrient.name)) {
    //                 ingredientNutrients.push(ingredientNutrient)
    //             }
    //         }
    //         ingredient['nutrition']['nutrients']=ingredientNutrients
    //     }
    //     recipeClone['nutrition']['nutrients']=nutrients
    //     newRecipeList.push(recipeClone)

    // }
    // console.log(JSON.stringify(newRecipeList).length)
    // console.log(JSON.stringify(recipeList).length)



    const { currentUser } = React.useContext(AuthContext)

    const db = getFirestore();
    const recipesRef = collection(db, "recipes")
    const recipeBooksRef = collection(db, "recipe_books")

    function saveRecipe(recipe) {

        if (userObject.loaded) {

            let tempDoc = doc(recipesRef, recipe.id)

            setDoc(tempDoc, recipe)
            // usersRef.doc(currentUser.uid).update(userObject).catch((err) => {
            //     console.error(err)
            // })
        }
        // setUserObject(userObject)
    }

    function saveRecipeBook(recipeBook) {

        if (userObject.loaded) {
            let tempDoc = doc(recipeBooksRef, recipeBook.id)
            setDoc(tempDoc, recipeBook)
        }
    }

    function setAndSaveRecipeBook(recipeBook) {
        saveRecipeBook(recipeBook)

        let recipeBookListClone = recipeBookList.concat([])
        let found = false
        for (let recBookIndex in recipeBookListClone) {
            if (recipeBookListClone[recBookIndex].id === recipeBook.id) {
                recipeBookListClone[recBookIndex] = recipeBook
                found = true
            }
        }
        if (!found) {
            recipeBookListClone.push(recipeBook)
        }
        setRecipeBookList(recipeBookListClone)
    }



    const loadRecipeBooksAndRecipes = async (recipeBooks) => {
        try {
            //this loads once but doesn't update via a listener
            const rbquery = query(recipeBooksRef, where(documentId(), 'in', recipeBooks),)
            const recipeBooksDoc = await getDocs(rbquery)

            let tempRecipeBooks = []


            recipeBooksDoc.forEach((doc) => {
                tempRecipeBooks.push(doc.data())

            })
            setRecipeBookList(tempRecipeBooks)

            //const rquery = query(recipesRef, where('recipeBooks', 'array-contains-any', recipeBooks),orderBy("name"),limit(10))
            const rquery = query(recipesRef, where('recipeBooks', 'array-contains-any', recipeBooks), orderBy("name"))

            const recipeDocs = await getDocs(rquery)
            let tempRecipes = []

            recipeDocs.forEach((doc) => {

                let recipe = doc.data()
                if (recipe.recipeBooks.includes(config.baseRecipeBookId)) {
                    tempRecipes.push(recipe)
                }
                else {
                    tempRecipes.unshift(recipe)
                }
            })

            setRecipeList(tempRecipes)
        }

        catch (e) {
            console.log(e)
        }
    }


    async function addOrModifyRecipe(recipe, pictureFile = undefined) {
        //if the recipe is new add an id
        if (!recipe.id) {
            let newRecipeId = uuidv4()
            recipe = { ...recipe, id: newRecipeId }

        }

        let oldRecipe = recipeList.find(r => r.id === recipe.id)

        if (pictureFile) {
            //picture upload
            let blob = pictureFile.slice(0, pictureFile.size, "image/jpeg");
            let newFile = new File([blob], `${recipe.id}-${Math.floor(Number.MAX_SAFE_INTEGER * Math.random())}.jpeg`, { type: "image/jpeg" });
            let formData = new FormData();
            if (oldRecipe) {
                formData.append("oldURL", oldRecipe.picture);
            }
            const idToken = await currentUser.getIdToken()

            formData.append("folder", currentUser.uid)
            formData.append("imgfile", newFile);

            const { data: response } = await axios.post('uploadPicture/', formData, { headers: { authorization: 'Bearer ' + idToken } })
            recipe['picture'] = response
        }

        let newRecipeList = recipeList.filter(r => r.id !== recipe.id)
        newRecipeList.unshift(recipe)

        setRecipeList(newRecipeList)

        // let newCart = userObject.cart.concat([])

        // for (let cartItemIndex in newCart) {
        //     let cartItem = newCart[cartItemIndex]
        //     if (cartItem.id === recipe.id) {
        //         newCart[cartItemIndex] = {
        //             ...cartItem, picture: recipe.picture, nutrition: recipe.nutrition,
        //             categories: recipe.categories, ingredients: recipe.ingredients,
        //             description: recipe.description, name: recipe.name, tags: recipe.tags, type: recipe.type
        //         }
        //     }
        // }

        // setUserObject({ ...userObject, cart: newCart })

        saveRecipe(recipe)

        ///add it to the recipeBooks
        if (!oldRecipe || oldRecipe.recipeBooks !== recipe.recipeBooks) {
            let newRecipeBooks = recipeBookList.concat([])

            for (let recBook of newRecipeBooks) {
                if (recipe.recipeBooks.includes(recBook.id) && !recBook.recipeIds.includes(recipe.id)) {
                    recBook.recipeIds.push(recipe.id)
                    saveRecipeBook(recBook)
                }
            }
            setRecipeBookList(newRecipeBooks)
        }
    }


    function getRecipesForRecipeBook(recipeBook) {
        let recipes = []
        for (let recipeItem of recipeList) {
            if (recipeItem.recipeBooks.includes(recipeBook)) {
                recipes.push(_.cloneDeep(recipeItem))
            }
        }
        return recipes
    }


    async function deleteRecipe(recipe) {
        const idToken = await currentUser.getIdToken()

        if (recipe.picture !== config.basePictureURL && recipe.picture.includes('thymeless-upload-storage')) {
            //before deleting picture we should make sure its not saved as a cooking recipe
            let cookingRecipeCollectionRef = collection(db, "users", currentUser.uid, "coookingRecipes")

            const querySnapshot = await getDocs(cookingRecipeCollectionRef);
            let foundRecipe = false
            querySnapshot.forEach((doc) => {
                // doc.data() is never undefined for query doc snapshots
                let tempDoc = doc.data()
                if (tempDoc.id === recipe.id) {

                    if (new Date(tempDoc.week) > (new Date() - 30)) {
                        foundRecipe = true
                    }
                }
            })
            if (!foundRecipe) {
                await axios.post('deletePicture', null, { headers: { authorization: 'Bearer ' + idToken }, params: { url: recipe.picture } })
            }
        }
        let recipeBookListClone = recipeBookList.concat([])
        let recipeListClone = recipeList.filter(r => r.id !== recipe.id)
        let recipeBookToChange;
        for (let recipeBookId of recipe.recipeBooks) {
            for (let recipeBook of recipeBookListClone) {
                if (recipeBook.id === recipeBookId) {
                    let filteredRecipeIds = recipeBook.recipeIds.filter(recipeId => recipeId !== recipe.id)
                    recipeBook['recipeIds'] = filteredRecipeIds
                    recipeBookToChange = recipeBook
                }
            }
        }

        const batchDBUpdate = writeBatch(db);
        const recipeBookDocRef = doc(recipeBooksRef, recipeBookToChange.id);

        batchDBUpdate.set(recipeBookDocRef, recipeBookToChange);

        const recipeDocRef = doc(recipesRef, recipe.id);

        batchDBUpdate.delete(recipeDocRef)

        await batchDBUpdate.commit()

        setRecipeBookList(recipeBookListClone)
        setRecipeList(recipeListClone)
    }

    function getCustomRecipeBookId() {
        for (let recBookIndex in recipeBookList) {
            let recBook = recipeBookList[recBookIndex]
            if (recBook.id !== config.baseRecipeBookId) {
                return recBook.id
            }
        }
    }

    async function copyRecipeToOwnRecipeBook(recipe) {
        let newRecipe = _.cloneDeep(recipe)
        let newRecipeId = uuidv4()
        newRecipe = { ...newRecipe, id: newRecipeId, recipeBooks: [], owners: [currentUser.uid] }
        const idToken = await currentUser.getIdToken()
        delete newRecipe['calendarDate'];
        delete newRecipe['calendarCategory'];
        delete newRecipe['pinned'];


        if (recipe.picture.includes('thymeless-upload-storage')) {
            const { data: response } = await axios.post('copyPicture', null, {
                headers: { authorization: 'Bearer ' + idToken },
                params: { sourceUrl: newRecipe.picture, folder: currentUser.uid, newName: `${newRecipe.id}-${Math.floor(Number.MAX_SAFE_INTEGER * Math.random())}.jpeg` }
            })
            newRecipe['picture'] = response
        }

        //need to ensure the custom recipe book has the id and the recipe has the recipebook id
        let newRecipeBooks = recipeBookList.concat([])
        let recipeBookCopiedTo;
        for (let recBookIndex in newRecipeBooks) {
            let recBook = newRecipeBooks[recBookIndex]
            if (recBook.id !== config.baseRecipeBookId) {
                //if the recipe book is not the bitetracker recipe book, add the recipe both ways
                recBook.recipeIds.push(newRecipe.id)
                newRecipe.recipeBooks.push(recBook.id)
                recipeBookCopiedTo = recipeBookList[recBookIndex]
                saveRecipeBook(recBook)
                saveRecipe(newRecipe)
            }
        }
        setRecipeBookList(newRecipeBooks)

        let newRecipeList = recipeList.concat([])
        newRecipeList.unshift(newRecipe)

        setRecipeList(newRecipeList)
        return recipeBookCopiedTo

    }


    async function copyRecipeToBiteTrackerRecipeBook(recipe) {
        let newRecipe = _.cloneDeep(recipe)
        let newRecipeId = uuidv4()
        newRecipe = { ...newRecipe, id: newRecipeId, recipeBooks: [], owners: [currentUser.uid] }
        const idToken = await currentUser.getIdToken()
        delete newRecipe['calendarDate'];
        delete newRecipe['calendarCategory'];
        delete newRecipe['pinned'];


        if (recipe.picture.includes('thymeless-upload-storage')) {
            const { data: response } = await axios.post('copyPicture', null, {
                headers: { authorization: 'Bearer ' + idToken },
                params: { sourceUrl: newRecipe.picture, folder: currentUser.uid, newName: `${newRecipe.id}-${Math.floor(Number.MAX_SAFE_INTEGER * Math.random())}.jpeg` }
            })
            newRecipe['picture'] = response
        }

        //need to ensure the custom recipe book has the id and the recipe has the recipebook id
        let newRecipeBooks = recipeBookList.concat([])
        let recipeBookCopiedTo;
        for (let recBookIndex in newRecipeBooks) {
            let recBook = newRecipeBooks[recBookIndex]
            if (recBook.id === config.baseRecipeBookId) {
                //if the recipe book is the bitetracker recipe book, add the recipe both ways
                recBook.recipeIds.push(newRecipe.id)
                newRecipe.recipeBooks.push(recBook.id)
                recipeBookCopiedTo = recipeBookList[recBookIndex]
                saveRecipeBook(recBook)
                saveRecipe(newRecipe)
            }
        }
        setRecipeBookList(newRecipeBooks)

        let newRecipeList = recipeList.concat([])
        newRecipeList.unshift(newRecipe)

        setRecipeList(newRecipeList)
        return recipeBookCopiedTo

    }


    function resetRecipeBookObjects() {
        //ony used during logout
        setRecipeBookList([])
        setRecipeList([])
    }




    return (
        <RecipeBooksContext.Provider value={{ recipeBookList: recipeBookList, recipeList: recipeList, setAndSaveRecipeBook: setAndSaveRecipeBook, loadRecipeBooksAndRecipes: loadRecipeBooksAndRecipes, addOrModifyRecipe: addOrModifyRecipe, getRecipesForRecipeBook: getRecipesForRecipeBook, deleteRecipe: deleteRecipe, getCustomRecipeBookId: getCustomRecipeBookId, copyRecipeToOwnRecipeBook: copyRecipeToOwnRecipeBook, copyRecipeToBiteTrackerRecipeBook: copyRecipeToBiteTrackerRecipeBook, resetRecipeBookObjects: resetRecipeBookObjects }}>
            {props.children}
        </RecipeBooksContext.Provider>

    );
};