import './dashboard_page.css'
import React from "react";
import { AuthContext } from 'contexts/auth_context';
import { CartContext } from 'contexts/cart_context';
import MobileSettingsButton from 'components/shared/mobile_settings_button/mobile_settings_button';
import { Capacitor } from '@capacitor/core';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { Dialog } from '@material-ui/core';
import LogWeight from 'components/shared/log_weight/log_weight';
import AssessmentOutlinedIcon from '@material-ui/icons/AssessmentOutlined';
import WeightOverTimeGraph from 'components/shared/graphs/weight_over_time_graph';
import BodyFatOverTimeGraph from 'components/shared/graphs/body_fat_over_time_graph';
import CaloriesOverTimeGraph from 'components/shared/graphs/calories_over_time_graph';

const DashboardPage = () => {
    const loadUsers = React.useContext(CartContext).loadUsers;
    const isNewlyWrittenUser = React.useContext(AuthContext).isNewlyWrittenUser
    const [logWeightDialogOpen, setLogWeightDialogOpen] = React.useState(false)
    const [tabValue, setTabValue] = React.useState(0)

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue)
    };

    function handleLogWeightClick() {
        setLogWeightDialogOpen(true)
    }

    const handleLogWeightExit = () => {
        setLogWeightDialogOpen(false)
    }

    React.useEffect(() => {

        loadUsers()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isNewlyWrittenUser]);




    return (<>    <div className={window.innerWidth > 500 ? "" : "dashboard-page"} style={{ minHeight: "calc(100% - 45px)", display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', maxWidth: '800px', margin: 'auto auto' }} >
        {Capacitor.getPlatform() !== "web" && <MobileSettingsButton />}


        <div style={{ marginBottom: '23px', fontFamily: 'Montserrat-SemiBold', fontSize: '24px' }}>Dashboard </div>
        <div style={{ marginTop: '5px', display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '15px', color: 'grey', width: '100%' }}>
    
            <Tabs TabIndicatorProps={{ style: { backgroundColor: "#2D7933" } }} value={tabValue} onChange={handleTabChange} >
                <Tab label="Weight" />
                <Tab label="Body fat" />
                <Tab label="Calories" />
            </Tabs>
            <button className="log-weight-button" onClick={handleLogWeightClick}> <AssessmentOutlinedIcon style={{ marginRight: '5px', marginLeft: '-1px' }} /> Log Weight</button>
        </div>

        {tabValue === 0 && <WeightOverTimeGraph />}
        {tabValue === 1 && <BodyFatOverTimeGraph />}
        {tabValue === 2 && <CaloriesOverTimeGraph/>}


    </div>
        <Dialog open={logWeightDialogOpen} maxWidth={'xl'} onClose={handleLogWeightExit} >
            <LogWeight calendarDate={new Date()} handleLogWeightExit={handleLogWeightExit}></LogWeight>
        </Dialog>
    </>)

}

export default DashboardPage;