import React from "react";

import { getDateMonthYear, parseDateMonthYear } from 'helpers/date_helpers';
import { AreaChart, Area, XAxis, YAxis, Tooltip, ResponsiveContainer, Brush, Scatter } from 'recharts';
import { getMealNutrientAmount } from "helpers/recipe_helpers";
import { getFirestore, collection, getDocs, query, where } from "firebase/firestore";
import { AuthContext } from "contexts/auth_context";
import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics';


const CaloriesOverTimeGraph = () => {
    const [calorieUserData, setCalorieUserData] = React.useState(null);
    const { currentUser } = React.useContext(AuthContext)


    React.useEffect(() => {


        const fetchData = async () => {
            try {
                //this loads once but doesn't update via a listener
                var db = getFirestore();

                let calendarMealCollectionRef = collection(db, "users", currentUser.uid, "calendarMeals")

                const ninetyDaysAgo = new Date();
                ninetyDaysAgo.setDate(ninetyDaysAgo.getDate() - 90);

                const querySnapshot = await getDocs(query(calendarMealCollectionRef, where('calendarDate', '>', ninetyDaysAgo)));
                let calorieData = {}

                querySnapshot.forEach((doc) => {
                    // doc.data() is never undefined for query doc snapshots
                    let tempDoc = doc.data()
                    if (tempDoc.calendarDate) {
                        tempDoc['calendarDate'] = new Date(tempDoc.calendarDate.seconds * 1000)

                        let dateMonthYear = getDateMonthYear(tempDoc.calendarDate)
                        //Object.keys(ingredientsToOrder).includes(recipeIngredient.name.toString()
                        if (Object.keys(calorieData).includes(dateMonthYear)) {
                            calorieData[dateMonthYear] = calorieData[dateMonthYear] + getMealNutrientAmount(tempDoc, 'Calories')
                        }
                        else {
                            calorieData[dateMonthYear] = getMealNutrientAmount(tempDoc, 'Calories')
                        }
                    }
                })
                setCalorieUserData(calorieData)
                FirebaseAnalytics.logEvent({
                    name: "saw_calories_dashboard",
                    params: {}
                })
                //
            }

            catch (e) {
                console.log(e)
            }
        };

        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); // Empty dependency array means this effect runs only once on component mount

    const formatDate = (tick) => {
        const date = new Date(tick);

        const month = date.toLocaleString('default', { month: 'short' }); // Get short month name (e.g., "Apr")

        const day = date.getDate(); // Get day of the month (e.g., 3)

        return `${month} ${day}`;
    };


    if (!calorieUserData) {
        // Display loading state while data is being fetched
        return (<ResponsiveContainer width="98%" height={300}>
            <div style={{ marginLeft: 'auto', marginRight: 'auto', textAlign: 'center', fontSize: '24px', color: 'black', marginTop: '70px' }}>Loading...</div>
            <div style={{ marginLeft: 'auto', marginRight: 'auto', textAlign: 'center', color: 'grey', marginTop: '10px' }}>Calculating calories tracked last 90 days</div>
        </ResponsiveContainer>)
    }

    let dataMax = 0;
    let dataMin = 1000;

    let calorieGraphData = []

    if (Object.keys(calorieUserData).length !== 0) {
        for (let key of Object.keys(calorieUserData)) {
            let date = parseDateMonthYear(key)
            calorieGraphData.push({ date: date, value: calorieUserData[key] })
            if (calorieUserData[key] > dataMax) {
                dataMax = calorieUserData[key]
            }
            if (calorieUserData[key] < dataMin) {
                dataMin = calorieUserData[key]
            }
        }


        calorieGraphData.sort((a, b) => (a.date - b.date));

        calorieGraphData.forEach((d) => {
            d.date = d.date.getTime(); // date -> epoch
        });


        return (<>
            <ResponsiveContainer width="98%" height={300}>
                <AreaChart data={calorieGraphData}>
                    <XAxis style={{ fontSize: '12px' }} interval={'preserveStartEnd'} dataKey="date" type="number" domain={['dataMin', 'dataMax']} tickFormatter={formatDate} />
                    <YAxis width={40} dataKey="value" domain={[dataMin => dataMin - 10, dataMax => dataMax + 10]} />
                    <Tooltip labelFormatter={(label) => new Date(label).toLocaleDateString()} formatter={(value) => value + ' cals'} />
                    <Area type="monotone" dataKey="value" stroke="rgb(161, 23, 76)" fill="rgb(239,230 , 218)" dot={{ stroke: 'rgb(161, 23, 76)', strokeWidth: 1, r: 4, strokeDasharray: '' }} />
                    <Brush dataKey="date" height={30} stroke="rgb(233, 198, 210)" fillOpacity={1} tickFormatter={formatDate} />
                    <Scatter dataKey="value" fill="#8884d8" />
                </AreaChart>
            </ResponsiveContainer>
            <div style={{ marginTop: '18px', fontSize:'12px', color: 'grey' }}> Pro-tip: It's better to skip whole days than to log partial days</div>

        </>)
    }
    else {
        return (<ResponsiveContainer width="98%" height={300}>
            <div style={{ marginLeft: 'auto', marginRight: 'auto', textAlign: 'center', fontSize: '24px', color: 'black', marginTop: '70px' }}>No data from last 90 days</div>

            <div style={{ marginLeft: 'auto', marginRight: 'auto', textAlign: 'center', color: 'grey', marginTop: '10px' }}>Track meals in calendar to see data</div>
        </ResponsiveContainer>

        )


    }

}

export default CaloriesOverTimeGraph;