import './ai_planning.css'
import React from "react";
import TextField from '@material-ui/core/TextField';
import axios from 'axios';
import { Alert } from '@material-ui/lab';
import LoopIcon from '@material-ui/icons/Loop';
import { AuthContext } from 'contexts/auth_context';
import { v4 as uuidv4 } from 'uuid';
import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics';
import AIPlanningRow from './ai_planning_row';
import { Dialog } from '@material-ui/core';
import AddRecipesToPlan from '../add_recipe_to_plan/add_recipes_to_plan';


const AIPlanning = (props) => {

    let AIRecipes = props.recipes
    let setAIRecipes = props.setRecipes
    const [recipesBeingAdded, setRecipesBeingAdded] = React.useState(false)





    function handleAddToPlan() {
        setRecipesBeingAdded(true)
       
    }

    let rows = []
    let allNotChecked = true
    for (let index in AIRecipes) {
        let AIRecipe = AIRecipes[index]
        if (AIRecipe.checked) {
            allNotChecked = false
        }

        rows.push(<AIPlanningRow recipe={AIRecipe} foodList={AIRecipes} setFoodList={setAIRecipes} index={index} />)
    }


    return (

        <>
            <div className="ai-planning">

                <div style={{ maxWidth: '90%', width: '375px' }} >
                    {AIRecipes.length !== 0 && <>{rows}
                        <div className="extract-recipe-dialog-form-submit-button"><button disabled={allNotChecked} className={!allNotChecked ? "order-ingredients-button-for-browser" : "order-ingredients-button-for-browser-disabled"} style={{ padding: '10px 20px', fontSize: '15px', fontFamily: 'Montserrat-SemiBold', width: '196px' }} onClick={handleAddToPlan} >Add to Plan</button></div>
                    </>}


                </div>
            </div>
            <Dialog open={Boolean(recipesBeingAdded)} onClose={() => { setRecipesBeingAdded(false) }} maxWidth={'xl'} >
                <AddRecipesToPlan threadId={props.threadId} setTabValue={props.setTabValue} prompt={prompt} recipes={AIRecipes} exitDialog={() => { setRecipesBeingAdded(false) }} />
            </Dialog>

        </>
    )

}

export default AIPlanning;