import React, { useState, useContext } from 'react';
import Button from '@material-ui/core/Button';
import CalendarAddCustomMeal from './calendar_add_custom_meal';
import CalendarAddRecipeMeal from './calendar_add_recipe_meal';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { Dialog } from '@material-ui/core';
import CalendarAddFromSearch from './calendar_add_from_search';
import { UserContext } from 'contexts/user_context';
import AITrackingForm from '../ai_tracking_form/ai_tracking_form';
import { CloseRounded } from '@material-ui/icons';

const CalendarAddMeal = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { userObject, setUserObject } = useContext(UserContext);
  const [isOverridingTabValue, setIsOverridingTabValue] = useState(props.tab !== undefined);

  const tabValue = isOverridingTabValue ? props.tab : userObject.addMealTabValue || 0;

  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleExitClick = () => {
    setAnchorEl(null);
    if (props.tab !== undefined) setIsOverridingTabValue(true);
  };

  const handleTabChange = (event, newValue) => {
    setUserObject({ ...userObject, addMealTabValue: newValue });
    setIsOverridingTabValue(false);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      {props.providedContent ? (
        <span style={{ display: 'flex', alignItems: 'center', width: 'calc(100% - 30px)' }} onClick={handleClick}>
          {props.content}
        </span>
      ) : (
        <Button className='add-button' onClick={handleClick}>
          {props.content}
        </Button>
      )}
      <Dialog className="calendar-add-meal-popover" id={id} open={open} anchorEl={anchorEl}>
        <div className="calendar-add-meal-modal">
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Tabs TabIndicatorProps={{ style: { backgroundColor: '#2D7933' } }} value={tabValue} onChange={handleTabChange}>
              <Tab label="Recipes" />
              <Tab label="Food search" />
              <Tab label="AI tracking" />
              <Tab label="Quick log" />
            </Tabs>
            <Button className='calendar-add-meal-modal-exit-button' onClick={handleExitClick}>
              <CloseRounded fontSize="inherit" />
            </Button>
          </div>
          {tabValue === 0 && (
            <CalendarAddRecipeMeal
              calendarDate={props.calendarDate}
              calendarCategory={props.calendarCategory}
              handleExitClick={handleExitClick}
              setSlotWasEmpty={props.setSlotWasEmpty}
            />
          )}
          {tabValue === 1 && (
            <CalendarAddFromSearch
              calendarDate={props.calendarDate}
              calendarCategory={props.calendarCategory || 'breakfast'}
              handleExitClick={handleExitClick}
              setSlotWasEmpty={props.setSlotWasEmpty}
            />
          )}
          {tabValue === 2 && (
            <AITrackingForm
              calendarDate={props.calendarDate}
              calendarCategory={props.calendarCategory || 'breakfast'}
              handleExitClick={handleExitClick}
              setSlotWasEmpty={props.setSlotWasEmpty}
            />
          )}
          {tabValue === 3 && (
            <CalendarAddCustomMeal
              calendarDate={props.calendarDate}
              calendarCategory={props.calendarCategory || 'breakfast'}
              handleExitClick={handleExitClick}
            />
          )}
        </div>
      </Dialog>
    </>
  );
};

export default CalendarAddMeal;
