import React from 'react';
import { Button, Checkbox, Dialog, Tooltip } from '@material-ui/core'
//  import FoodSearchRow from './branded_product_search_row';
import { config } from 'Constants';
import _ from 'lodash';
import { CartContext } from 'contexts/cart_context';

import MealPortioningSlider from '../meal_portioning_slider/meal_portioning_slider';

import { decimalToFraction, getMealNutrientAmount, recalculateCaloriesForRecipe } from 'helpers/recipe_helpers';
import MealDetailsCard from '../meal_details_card/meal_details_card';


const AIPlanningRow = (props) => {
    let recipe = props.recipe
    let foodList = props.foodList
    let setFoodList = props.setFoodList
    let index = props.index
    const [recipeBeingViewed, setRecipeBeingViewed] = React.useState(false)

    const handleRecipeViewClick = () => {
        setRecipeBeingViewed(true)

    }

    const handleRecipeViewExitClick = () => {
        setRecipeBeingViewed(false)

    }

    function setPortion(portion, index) {
        // Deep clone the foodList
        let foodListClone = structuredClone(foodList); // Use structuredClone if available in your environment, otherwise use JSON.parse(JSON.stringify(foodList))
        let recipeClone = foodListClone[index];
        for (let ingredient of recipeClone.ingredients) {

            ingredient.amountString = decimalToFraction(ingredient.amount * portion / recipeClone.portion);
            ingredient.amount = ingredient.amount * portion / recipeClone.portion;

        }


        recipeClone = recalculateCaloriesForRecipe(recipeClone);
        recipeClone['portion'] = portion

        setFoodList(foodListClone);
    }

    function setAIRecipe(rec) {
        let foodListClone = foodList.concat([])
        foodListClone[index] = rec
        setFoodList(foodListClone)

    }

    function toggleChecked() {
        let foodListClone = foodList.concat([])
        foodListClone[index].checked = !foodListClone[index].checked
        setFoodList(foodListClone)

    }


    return (<>
        <div style={{ borderBottom: '1px solid #e1e1e1' }}>
            <div key={`calendar-add-menu-meal-row-${recipe.cooking_recipe_id}`} className="calendar-add-menu-meal-row" style={{  maxWidth: '100%' }}>

                <Checkbox className="ai-tracking-form-checkbox" style={{ marginLeft: '-3px' }} checked={recipe.checked} onChange={toggleChecked}></Checkbox>
                <img className="calendar-add-menu-meal-picture" style={{ marginRight: '3px' }} value={recipe.cooking_recipe_id} onClick={handleRecipeViewClick} src={recipe.picture} alt={recipe.name} />

                <div className="calendar-add-menu-meal-column" style={{ overflow: 'hidden' }} >
                    <div style={{ display: 'flex', maxWidth: '100%' }}>
                        <div className="calendar-add-menu-meal-name" style={{ margin: '7px 10px 7px 0px' }}>{recipe.name}
                        </div>
                    </div>
                    <div className="food-search-box-inner-row">

                        <div >

                            {getMealNutrientAmount(recipe, "Calories") > 0 && <div className="calendar-add-menu-meal-caloric-info">
                                <span className="caloric-chip caloric-chip-calories">{getMealNutrientAmount(recipe, "Calories")} Cals</span>
                                <span className="caloric-chip caloric-chip-protein">{getMealNutrientAmount(recipe, "Protein")}g</span>
                                <span className="caloric-chip caloric-chip-carbs">{getMealNutrientAmount(recipe, "Carbohydrates")}g</span>
                                <span className="caloric-chip caloric-chip-fats">{getMealNutrientAmount(recipe, "Fat")}g</span>
                            </div>}




                            <div style={{ display: 'flex', alignItems: 'center', fontSize: '13px', marginBottom:'20px' }}>
                                Servings: <span style={{ marginLeft: '10px', marginBottom: '-6px', width: '400px' }}>
                                    <MealPortioningSlider portion={recipe.portion} setPortion={setPortion} index={index} shrink={true} smallPopup={true} showLabel={true} />
                                    </span>
                            </div>
                        </div>


                    </div>


                </div>
            </div>

        </div>
        <Dialog open={recipeBeingViewed} maxWidth={'xl'} >
            <MealDetailsCard index={index} addToCalendarEnabled={false} disableCopy={false} portion={recipe.portion} setPortion={setPortion} handleExitClick={handleRecipeViewExitClick} setAIRecipe={setAIRecipe} meal={recipe} />
        </Dialog>
    </>

    )
}
export default AIPlanningRow