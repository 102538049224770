import React, { useState, useContext, useEffect } from 'react';
import axios from 'axios';
import ReactMarkdown from 'react-markdown';
import { Link } from 'react-router-dom';
import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics';
import { getFirestore, collection, getDocs, query, where, doc, updateDoc, arrayUnion, setDoc, deleteDoc } from "firebase/firestore";
import { AuthContext } from 'contexts/auth_context';
import { getDateMonthYear } from 'helpers/date_helpers';
import { getMealNutrientAmount } from "helpers/recipe_helpers";
import { UserContext } from 'contexts/user_context';
import { CartContext } from 'contexts/cart_context';
import LoopIcon from '@material-ui/icons/Loop';

const AIConsultationPage = () => {
    const [messages, setMessages] = useState([{ content: 'Hello, how may I help you? You can ask me for example: \n * Help me adjust my macros \n * Review my progress thus far \n \n Note if you need help with recipes visit the [planning tab](/planning)', role: 'assistant' }]);
    const [input, setInput] = useState('');
    const [threadId, setThreadId] = useState(null);
    const [threads, setThreads] = useState([]); // State to store all threads
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null); // State to handle error messages
    const [caloriesAndMacrosConsumed, setCaloriesAndMacrosConsumed] = useState(null);
    const { currentUser } = useContext(AuthContext);
    const userObject = useContext(UserContext).userObject;
    const loadUsers = useContext(CartContext).loadUsers;
    const isNewlyWrittenUser = useContext(AuthContext).isNewlyWrittenUser;

    useEffect(() => {
        loadUsers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isNewlyWrittenUser]);

    useEffect(() => {
        const fetchThreads = async () => {
            try {
                const db = getFirestore();
                const threadsCollection = collection(db, "users", currentUser.uid, "consultationThreads");
                const threadsSnapshot = await getDocs(threadsCollection);
                let threadsData = [];

                threadsSnapshot.forEach((doc) => {
                    threadsData.push({ id: doc.id, ...doc.data(), createdAt: doc.data().createdAt?.toDate() });
                });

                // Sort threads by date
                threadsData.sort((a, b) => b.createdAt - a.createdAt);

                setThreads(threadsData);
                if (threadsData.length > 0) {
                    setThreadId(threadsData[0].threadId);
                    setMessages(threadsData[0].messages);
                }
            } catch (e) {
                console.log(e);
            }
        };
        fetchThreads();
    }, []); // Empty dependency array means this effect runs only once on component mount

    useEffect(() => {
        const fetchData = async () => {
            try {
                const db = getFirestore();
                let calendarMealCollectionRef = collection(db, "users", currentUser.uid, "calendarMeals");

                const ninetyDaysAgo = new Date();
                ninetyDaysAgo.setDate(ninetyDaysAgo.getDate() - 90);

                const querySnapshot = await getDocs(query(calendarMealCollectionRef, where('calendarDate', '>', ninetyDaysAgo)));
                let calorieData = {};

                querySnapshot.forEach((doc) => {
                    let tempDoc = doc.data();
                    if (tempDoc.calendarDate) {
                        tempDoc['calendarDate'] = new Date(tempDoc.calendarDate.seconds * 1000);

                        let dateMonthYear = getDateMonthYear(tempDoc.calendarDate);
                        if (Object.keys(calorieData).includes(dateMonthYear)) {
                            calorieData[dateMonthYear].calories += getMealNutrientAmount(tempDoc, 'Calories');
                            calorieData[dateMonthYear].fat += getMealNutrientAmount(tempDoc, 'Fat');
                            calorieData[dateMonthYear].carbs += getMealNutrientAmount(tempDoc, 'Carbohydrates');
                            calorieData[dateMonthYear].protein += getMealNutrientAmount(tempDoc, 'Protein');
                        } else {
                            calorieData[dateMonthYear] = {
                                calories: getMealNutrientAmount(tempDoc, 'Calories'),
                                fat: getMealNutrientAmount(tempDoc, 'Fat'),
                                carbs: getMealNutrientAmount(tempDoc, 'Carbohydrates'),
                                protein: getMealNutrientAmount(tempDoc, 'Protein')
                            };
                        }
                    }
                });
                setCaloriesAndMacrosConsumed(calorieData);
            } catch (e) {
                console.log(e);
            }
        };
        fetchData();
    }, []); // Empty dependency array means this effect runs only once on component mount

    const handleSendMessage = async () => {
        if (input.trim() === '') return;

        const userMessage = { role: 'user', content: input };
        setMessages(prevMessages => [...prevMessages, userMessage]);

        const currentInput = input;
        setInput('');
        setLoading(true);
        setError(null);

        try {
            const response = await axios.get('consultWithAI', {
                params: {
                    threadId,
                    message: currentInput,
                    caloriesAndMacrosConsumed: caloriesAndMacrosConsumed,
                    weightHistory: userObject.weight,
                    caloriesAndMacrosTargeted: { calories: userObject.calories, fat: userObject.fats, carbs: userObject.carbs, protein: userObject.protein }
                }
            });

            if (response.data) {
                let newMessages = [userMessage];
                for (let msg of response.data) {
                    if (msg.role !== "user") {
                        let response = msg.content[0].text.value;
                        let isExisting = false
                        for (let existingMsg of messages) {
                            if (existingMsg.content === response) {
                                isExisting = true
                            }
                        }
                        if (!isExisting) {
                            newMessages.push({ role: msg.role, content: response });
                        }
                    }
                }

                const db = getFirestore();
                if (!threadId && response.data.length > 0) {
                    const newThreadId = response.data[0].thread_id;
                    setThreadId(newThreadId);

                    const threadRef = doc(db, "users", currentUser.uid, "consultationThreads", newThreadId);
                    await setDoc(threadRef, { threadId: newThreadId, messages: newMessages, createdAt: new Date() });

                    setThreads(prevThreads => [{ id: newThreadId, threadId: newThreadId, messages: [...messages, ...newMessages], createdAt: new Date() }, ...prevThreads]);
                } else {
                    const threadDoc = doc(db, "users", currentUser.uid, "consultationThreads", threadId);
                    await updateDoc(threadDoc, {
                        messages: arrayUnion(...newMessages)
                    });
                    
                    FirebaseAnalytics.logEvent({
                        name: "consult_with_ai_message",
                        params: { aiMessageNumber: messages.length, aiThreadId: response.data[0].thread_id, userMessage: userMessage.content, agentMessage: newMessages[1] ? newMessages[1].content : '' }
                    });

                    setThreads(prevThreads => {
                        const updatedThreads = prevThreads.map(thread => {
                            if (thread.threadId === threadId) {
                                return {
                                    ...thread,
                                    messages: [...thread.messages, ...newMessages]
                                };
                            }
                            return thread;
                        });
                        return updatedThreads;
                    });

                }

                setMessages(prevMessages => [...prevMessages, ...newMessages.slice(1)]); // Append only assistant messages

              
            }
        } catch (error) {
            console.error('Error sending message:', error);
            setError('Failed to send message. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    const handleTabClick = (selectedThreadId) => {
        const selectedThread = threads.find(thread => thread.threadId === selectedThreadId);
        setThreadId(selectedThreadId);
        setMessages(selectedThread.messages);
    };

    const handleCreateNewThread = () => {
        setThreadId(null);
        setMessages([{ content: 'Hello, how may I help you? You can ask me for example: \n * Help me adjust my macros \n * Review my progress thus far \n \n Note if you need help with recipes visit the [planning tab](/planning)', role: 'assistant' }]);
    };

    const handleDeleteThread = async (threadIdToDelete) => {
        const db = getFirestore();
        await deleteDoc(doc(db, "users", currentUser.uid, "consultationThreads", threadIdToDelete));
        setThreads(prevThreads => prevThreads.filter(thread => thread.threadId !== threadIdToDelete));
        if (threadId === threadIdToDelete) {
            setThreadId(null);
            setMessages([]);
        }
    };

    const formatDate = (date) => {
        const options = { month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true };
        return date.toLocaleDateString('en-US', options);
    };

    const renderers = {
        link: ({ href, children }) => {
            if (href.startsWith('/')) {
                return <Link to={href}>{children}</Link>;
            }
            return <a href={href}>{children}</a>;
        }
    };

    return (
        <div className="chat-container">
            <div className="chat-top-message">AI Dietary Consultation (Beta)</div>
            <div className="chat-top-message-secondary">Get personalized recommendations from our AI agent based on your historical tracking & weight data</div>

            <div className="tabs-container-wrapper">
                <div className="tabs-container">
                    {threads.map((thread) => (
                        <div key={thread.id} className="tab-container">
                            <div className={`tab ${thread.threadId === threadId ? 'active' : ''}`}>
                                <button style={{ background: 'none', border: 'none', color: 'white' }} onClick={() => handleTabClick(thread.threadId)}>
                                    {formatDate(thread.createdAt)}
                                </button>
                                <button style={{ background: 'none', border: 'none', color: 'white', marginLeft: '0px', marginRight: '-5px' }} onClick={() => handleDeleteThread(thread.threadId)}>X</button>
                            </div>
                        </div>
                    ))}
                </div>
                <button className="new-thread-button" onClick={handleCreateNewThread}>+</button>
            </div>

            <div className="chat-box" style={{ height: 'calc(100dvh - 280px)' }}>
                {messages.map((msg, index) => (
                    <div key={index} className={`chat-message ${msg.role}`}>
                        <ReactMarkdown components={renderers}>{msg.content}</ReactMarkdown>
                    </div>
                ))}
                {error && <div className="chat-error">{error}</div>} {/* Display error message */}
            </div>
            <div className="chat-input-container">
                <input
                    type="text"
                    value={input}
                    onChange={(e) => setInput(e.target.value)}
                    onKeyPress={(e) => e.key === 'Enter' ? handleSendMessage() : null}
                    className="chat-input"
                    placeholder="Type your message..."
                    disabled={loading}
                />
                <button onClick={handleSendMessage} className="send-button" disabled={loading}>
                    {loading ? <>Sending <LoopIcon style={{ animation: 'spin 2s linear infinite', marginLeft: '2px', fontSize: '20px' }}/></> : 'Send'}
                </button>
            </div>
        </div>
    );
};

export default AIConsultationPage;
