import './calorie_card.css';
import './carousel.scss'
//React, router
import React from 'react';

//context
import { UserContext } from 'contexts/user_context';

import Carousel from 'react-bootstrap/Carousel';


import { ProgressBar } from 'react-bootstrap';




const CalorieCard = (props) => {
    const userObject = React.useContext(UserContext).userObject;








    return (<>
        <div className="calorie-card" style={{ display: 'flex', boxShadow: ' 0px 1px 2px 0px #0000000D', width: '100%', borderTop: '1px solid #dadada', justifyContent: 'space-around', paddingBottom: '20px', paddingTop: '12px', background: window.innerWidth < 760 ? 'white' : '' }}>
            <Carousel interval={null}>
                <Carousel.Item>
                    <div style={{ display: 'flex', justifyContent: 'space-around', width: 'calc(100% - 28px)', marginLeft: 'auto', marginRight: 'auto' }}>
                        <div style={{ width: '31%', fontSize: '12px', color: '#333333', fontFamily: 'Montserrat-SemiBold' }}>Protein

                            <ProgressBar className="macro-totals-bar" max={1}>
                                <ProgressBar className="macro-totals-bar-protein" label={``} now={props.totalProteins} key={6} max={1} />
                                <ProgressBar className="macro-totals-bar-protein-empty" label={''} now={!parseInt(userObject.proteins) && props.totalProteins === 0 ? 1 : userObject.proteins - props.totalProteins > 0 ? userObject.proteins - props.totalProteins : 0} key={1} max={1} />


                            </ProgressBar>

                            <div style={{ color: '#999999', fontFamily: 'Montserrat-Medium', fontSize: '11px' }}>{props.totalProteins}g {userObject.proteins ? <>of {userObject.proteins}g</> : ''}</div>
                        </div>
                        <div style={{ width: '31%', fontSize: '12px', color: '#333333', fontFamily: 'Montserrat-SemiBold' }}>Carbs

                            <ProgressBar className="macro-totals-bar" max={1}>

                                <ProgressBar className="macro-totals-bar-carbs" label={``} now={props.totalCarbs} key={5} max={1} />
                                <ProgressBar className="macro-totals-bar-carbs-empty" label={''} now={!parseInt(userObject.carbs) && props.totalCarbs === 0 ? 1 : userObject.carbs - props.totalCarbs > 0 ? userObject.carbs - props.totalCarbs : 0} key={2} max={1} />


                            </ProgressBar>
                            <div style={{ color: '#999999', fontFamily: 'Montserrat-Medium', fontSize: '11px' }}>{props.totalCarbs}g {userObject.carbs ? <>of {userObject.carbs}g</> : ''}</div>


                        </div>

                        <div style={{ width: '31%', fontSize: '12px', color: '#333333', fontFamily: 'Montserrat-SemiBold' }}>Fat

                            <ProgressBar className="macro-totals-bar" max={1}>

                                <ProgressBar className="macro-totals-bar-fats" label={``} now={props.totalFats} key={4} max={1} />
                                <ProgressBar className="macro-totals-bar-fats-empty" label={``} now={!parseInt(userObject.fats) && props.totalFats === 0 ? 1 : userObject.fats - props.totalFats > 0 ? userObject.fats - props.totalFats : 0} key={3} max={1} />

                            </ProgressBar>
                            <div style={{ color: '#999999', fontFamily: 'Montserrat-Medium', fontSize: '11px' }}> {props.totalFats}g {userObject.fats ? <>of {userObject.fats}g</> : ''}</div>
                        </div>
                    </div>
                </Carousel.Item>
                <Carousel.Item>
                    <div style={{ display: 'flex', justifyContent: 'space-around', width: 'calc(100% - 28px)', marginLeft: 'auto', marginRight: 'auto' }}>

                        <div style={{ width: '31%', fontSize: '12px', color: '#333333', fontFamily: 'Montserrat-SemiBold' }}>Sodium

                            <ProgressBar className="macro-totals-bar" max={1}>
                                <ProgressBar className="macro-totals-bar-sodium" label={``} now={props.totalSodium} key={6} max={1} />
                                <ProgressBar className="macro-totals-bar-sodium-empty" label={''} now={props.totalSodium === 0 ? 1 : 0} key={1} max={1} />


                            </ProgressBar>

                            <div style={{ color: '#999999', fontFamily: 'Montserrat-Medium', fontSize: '11px' }}>{props.totalSodium} mg </div>
                        </div>
                        <div style={{ width: '31%', fontSize: '12px', color: '#333333', fontFamily: 'Montserrat-SemiBold' }}>Sugar

                            <ProgressBar className="macro-totals-bar" max={1}>

                                <ProgressBar className="macro-totals-bar-sugar" label={``} now={props.totalSugar} key={5} max={1} />
                                <ProgressBar className="macro-totals-bar-sugar-empty" label={''} now={props.totalSugar === 0 ? 1 : 0} key={2} max={1} />


                            </ProgressBar>
                            <div style={{ color: '#999999', fontFamily: 'Montserrat-Medium', fontSize: '11px' }}>{props.totalSugar} g </div>


                        </div>

                        <div style={{ width: '31%', fontSize: '11px', color: '#333333', fontFamily: 'Montserrat-SemiBold' }}>Cholesterol

                            <ProgressBar className="macro-totals-bar" max={1}>

                                <ProgressBar className="macro-totals-bar-cholesterol" label={``} now={props.totalCholesterol} key={4} max={1} />
                                <ProgressBar className="macro-totals-bar-cholesterol-empty" label={``} now={props.totalCholesterol === 0 ? 1 : 0} key={3} max={1} />

                            </ProgressBar>
                            <div style={{ color: '#999999', fontFamily: 'Montserrat-Medium', fontSize: '11px' }}> {props.totalCholesterol} mg</div>
                        </div>
                    </div>
                </Carousel.Item>
            </Carousel>

        </div>


    </>

    );


}
export default CalorieCard