import React from 'react';
// import Button from '@material-ui/core/Button';


// import { UserContext } from 'contexts/user_context';
// import { Tooltip } from '@material-ui/core';
import { getMealNutrientAmount } from 'helpers/recipe_helpers';
import { UserContext } from 'contexts/user_context';
import { Tooltip } from '@material-ui/core';

import CalorieCard from '../calorie_card/calorie_card';

import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';




const CalendarHeader = (props) => {
    const userObject = React.useContext(UserContext).userObject;
    const setUserObjectNoSave = React.useContext(UserContext).setUserObjectNoSave


    function moveCalendarPosition(amount) {
        let calendarPosition = userObject.calendarPosition
        if (!calendarPosition) {
            calendarPosition = 30
        }
        setUserObjectNoSave({ ...userObject, calendarPosition: calendarPosition + amount, calendarPositionModified: true })
    }




    function determineDay() {
        // const ordinals = ["", "1st", "2nd", "3rd", "4th", "5th", "6th", "7th", "8th", "9th", "10th", "11th", "12th", "13th", "14th", "15th",
        //     "16th", "17th", "18th", "19th", "20th", "21st", "22nd", "23rd", "24th", "25th", "26th", "27th", "28th", "29th", "30th", "31st"];
        return [props.calendarDate.getDate(), props.calendarDate.toLocaleDateString('en-US', { weekday: 'long' }), props.calendarDate.getMonth() + 1]
    }



    function calculateVerticalTotal(cartMeals, attribute) {
        let total = 0
        for (let meal of cartMeals) {
            if (meal.nutrition) {
                let mealNutrientAmount = getMealNutrientAmount(meal, attribute)
                if (mealNutrientAmount) {
                    total += mealNutrientAmount
                }
            }
        }
        return total
    }


 

    

    let totalCalories = calculateVerticalTotal(props.cartMeals, "Calories")
    let totalProteins = calculateVerticalTotal(props.cartMeals, "Protein")
    let totalCarbs = calculateVerticalTotal(props.cartMeals, "Carbohydrates")
    let totalFats = calculateVerticalTotal(props.cartMeals, "Fat")
    let totalSodium = calculateVerticalTotal(props.cartMeals, "Sodium")
    let totalSugar = calculateVerticalTotal(props.cartMeals, "Sugar")
    let totalCholesterol = calculateVerticalTotal(props.cartMeals, "Cholesterol")



    let [date, day, month] = determineDay()


    return (
        <div className="calendar-header" >
            <div className="calendar-header-top">
                {window.innerWidth <= 595 && userObject.calendarPosition > 0 && <div onClick={() => { moveCalendarPosition(-1) }} style={{ margin: '3px 10px 0px 0px' }}> <Tooltip title="go to previous day"><NavigateBeforeIcon /></Tooltip></div>}


                {props.cartMeals.length > 0 || userObject.calories ? day.substring(0, 3) : day} {month}/{date}
                {!!(props.cartMeals.length > 0 || userObject.calories) && <> <div className="calendar-header-calories" style={{ color: (totalCalories - totalCalories/10) > parseInt(userObject.calories) ? '#ba0000' : 'black' }}> Cals: {totalCalories} {userObject.calories ? "of " + userObject.calories : ''} </div>

                     {/* {!isDayInPast && (props.cartMeals.length > 0 || isGenerateLoading) && <Tooltip title="Regenerate meals for the day" >
                        <Button onClick={handleGenerateClick} disabled={isGenerateLoading} className="calendar-generate button"><LoopIcon style={isGenerateLoading ? { animation: 'spin 2s linear infinite', color:'#292929' } : {color:'#292929'}} /></Button>
                    </Tooltip>}  */}
                </>
                }
                {window.innerWidth <= 595 && userObject.calendarPosition < 60 && <div onClick={() => { moveCalendarPosition(1) }} style={{ margin: '3px 0px 0px 10px' }}> <Tooltip title="go to next day"><NavigateNextIcon /></Tooltip></div>}

            </div>
            <CalorieCard totalProteins={totalProteins} totalCarbs={totalCarbs} totalFats={totalFats} totalSodium={totalSodium} totalSugar={totalSugar} totalCholesterol={totalCholesterol}></CalorieCard>


         


        </div>

    )
}

export default CalendarHeader;