import React, { useContext, useState } from 'react';
import './recipe_cooking_planner.css';
import { getWeeks } from 'helpers/date_helpers';
import WeekSelector from '../week_selector/week_selector';
import RecipeCookingPlannerRow from './recipe_cooking_planner_row';
import { CartContext } from 'contexts/cart_context';

// Dummy data for recipes


const weeks = getWeeks(5,2);

const RecipeCookingPlanner = () => {
    const cookingRecipeList = useContext(CartContext).cookingRecipeList
    const [selectedWeek, setSelectedWeek] = useState(weeks[2].startOfWeek);

    let recipeList = []

    for (let index in cookingRecipeList) {
        let recipe = cookingRecipeList[index]
        if (recipe.week === selectedWeek) {
            recipeList.push(<RecipeCookingPlannerRow recipe={recipe} recipeList={cookingRecipeList} setRecipeList={null} index={index} />)
        }
    }

    return (
        <div className="recipe-cooking-planner">
            <WeekSelector weeks={weeks} selectedWeek={selectedWeek} setSelectedWeek={setSelectedWeek} />
            <div className="cooking-recipe-list">

                {recipeList.length > 0 ? recipeList :
                    <div style={{fontFamily:'Montserrat', marginTop:'10px'}}>No recipes planned for this week</div>
                }
            </div>
        </div>
    );
};

export default RecipeCookingPlanner;
