import { Dialog, Checkbox, Button, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import React from 'react';
import { CartContext } from 'contexts/cart_context';
import _ from 'lodash';

export default function ShoppingListDialog(props) {
    const prepareIngredientsToOrderWithUnits = React.useContext(CartContext).prepareIngredientsToOrderWithUnits;
    let { checkedIngredients, setCheckedIngredients } = React.useContext(CartContext);

    // Prepare ingredients to order based on the provided week.
    let ingredientsToOrder = prepareIngredientsToOrderWithUnits(props.week);

    // Function to toggle the checked state of an ingredient.
    function toggleChecked(ingredientName) {
        let checkedIngredientsClone = _.cloneDeep(checkedIngredients);
        if (!Object.keys(checkedIngredientsClone).includes(props.week)) {
            checkedIngredientsClone[props.week] = [];
        }
        let checkedIngredientsForWeek = checkedIngredientsClone[props.week];
        if (checkedIngredientsForWeek.includes(ingredientName)) {
            checkedIngredientsForWeek = checkedIngredientsForWeek.filter(item => item !== ingredientName);
        } else {
            checkedIngredientsForWeek = [...checkedIngredientsForWeek, ingredientName];
        }
        checkedIngredientsClone[props.week] = checkedIngredientsForWeek;
        setCheckedIngredients(checkedIngredientsClone);
    }

    // Generate rows for each ingredient.
    let rows = [];
    for (let ingredient of ingredientsToOrder) {
        const isChecked = checkedIngredients[props.week] ? checkedIngredients[props.week].includes(ingredient.name) : false;
        const textStyle = {
            textDecoration: isChecked ? 'line-through' : 'none',
            color: isChecked ? 'grey' : '#252525',
            fontSize: '14px',
            display: 'flex',
            alignItems: 'center'
        };


        let amountString = `${ingredient.amount} ${ingredient.unit ? ingredient.unit : ""}`;

        rows.push(
            <div style={{ display: 'flex', alignItems: 'center' }} key={ingredient.name}>
                <Checkbox
                    className="ai-tracking-form-checkbox"
                    style={{ marginLeft: '-3px' }}
                    checked={isChecked}
                    onChange={() => { toggleChecked(ingredient.name); }}
                />
                <span style={textStyle}>
                    <div className="order-recipe-ingredient-image-div"><img className="order-recipe-ingredient-image" src={'https://spoonacular.com/cdn/ingredients_100x100/' + ingredient.image}></img></div>
                    {amountString} {amountString.length>2?"of":""} {ingredient.name}</span>
            </div>
        );
    }

    return (
        <Dialog maxWidth={'xl'} open={true} onClose={props.onClose}>
            <DialogTitle style={{ textAlign: 'center', margin: '10px 30px -10px 30px' }}>
                Shopping List
            </DialogTitle>
            <div style={{ padding: '10px' }}>
                {rows}
            </div>
            <DialogActions>
                <Button onClick={props.onClose} color="primary">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
}
