import React, { useState, useContext, useEffect } from 'react';
import axios from 'axios';
import ReactMarkdown from 'react-markdown';
import './chat_with_ai.css'; // Import a CSS file for styling
import { RecipeBooksContext } from 'contexts/recipe_books_context';
import { UserContext } from 'contexts/user_context';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@material-ui/core';
import AIPlanning from '../ai_planning/ai_planning';
import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics';
import LoopIcon from '@material-ui/icons/Loop';
import { decimalToFraction, recalculateCaloriesForRecipe } from 'helpers/recipe_helpers';
import _ from 'lodash';
import { getFirestore, collection, getDocs, doc, updateDoc, arrayUnion, setDoc, deleteDoc } from "firebase/firestore";
import { AuthContext } from 'contexts/auth_context';

const Chat = (props) => {
    const [messages, setMessages] = useState([{ content: 'Hello, how may I help you? You can ask me for example: \n * Plan a vegan mediterranean dinner \n * Recommend a breakfast that is high on protein and around 500 calories \n \n Note if you need help adjusting your macros consult with the [dietary consultant](/ai_consultation)', role: 'assistant' }]);
    const [input, setInput] = useState('');
    const [threadId, setThreadId] = useState(null);
    const [threads, setThreads] = useState([]); // State to store all threads
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null); // State to handle error messages
    const [dialogOpen, setDialogOpen] = useState(false); // State for dialog
    const [recipes, setRecipes] = useState([]); // State for storing recipes
    const recipeList = useContext(RecipeBooksContext).recipeList;
    const { userObject } = useContext(UserContext);
    const { currentUser } = useContext(AuthContext);

    const db = getFirestore(); // Initialize Firestore

    useEffect(() => {
        const fetchThreads = async () => {
            try {
                const threadsCollection = collection(db, "users", currentUser.uid, "mealPlannerThreads");
                const threadsSnapshot = await getDocs(threadsCollection);
                let threadsData = [];

                threadsSnapshot.forEach((doc) => {
                    threadsData.push({ id: doc.id, ...doc.data(), createdAt: doc.data().createdAt?.toDate() });
                });

                // Sort threads by date
                threadsData.sort((a, b) => b.createdAt - a.createdAt);

                setThreads(threadsData);
                if (threadsData.length > 0) {
                    setThreadId(threadsData[0].threadId);
                    setMessages(threadsData[0].messages);
                    setRecipes(threadsData[0].recipes || []);
                }
            } catch (e) {
                console.error(e);
            }
        };
        fetchThreads();
    }, [db, userObject.uid]);

    const handleSendMessage = async () => {
        if (input.trim() === '') return;

        const userMessage = { role: 'user', content: input };
        setMessages(prevMessages => [...prevMessages, userMessage]);

        const currentInput = input;
        setInput('');
        setLoading(true);
        setError(null);

        try {
            const response = await axios.get('recipe/chatWithAI', {
                params: {
                    threadId,
                    message: currentInput,
                    dietaryRestrictions: userObject.dietaryRestrictions,
                    dislikedIngredients: userObject.dislikedIngredients
                }
            });

            if (response.data) {
                let newMessages = [];
                let recipeData = [];

                for (let msg of response.data) {
                    if (msg.role !== "user") {
                        let jsonContent = JSON.parse(msg.content[0].text.value);
                        let isExisting = false
                        for (let existingMsg of messages) {
                            if (existingMsg.content === jsonContent.responseForUser) {
                                isExisting = true
                            }
                        }
                        if (!isExisting) {
                            newMessages.push({ role: msg.role, content: jsonContent.responseForUser });
                            recipeData = jsonContent.recipes;
                        }
                    }
                }

                const actualRecipes = recipeData.map(recipe => {
                    const foundRecipe = recipeList.find(r => r.id === recipe.recipeId);
                    return { ...foundRecipe, portion: 1, cooking_recipe_id: recipe.recipeId };
                });

                let recipesToStore = [];
                let badRecipe = false
                for (let recipe of actualRecipes) {
                    let rec = _.cloneDeep(recipe);
                    if (rec.ingredients) {
                        for (let ingredient of rec.ingredients) {
                            ingredient.amountString = decimalToFraction(ingredient.amount * rec.portion);
                            ingredient.amount = ingredient.amount * rec.portion;
                        }
                        rec = recalculateCaloriesForRecipe(rec);
                        recipesToStore.push(rec);
                    }
                    else {
                        badRecipe = rec.cooking_recipe_id
                    }
                }

                if (badRecipe) {
                    FirebaseAnalytics.logEvent({
                        name: "chat_with_ai_wrong_recipe",
                        params: { aiMessageNumber: messages.length, aiThreadId: response.data[0].thread_id, wrongRecipeId: badRecipe }
                    });
                }

                setRecipes(recipesToStore);

                const newMessagesToStore = [userMessage, ...newMessages];
                setMessages(prevMessages => [...prevMessages, ...newMessages]);

                if (!threadId && response.data.length > 0) {
                    const newThreadId = response.data[0].thread_id;
                    setThreadId(newThreadId);

                    const threadRef = doc(db, "users", currentUser.uid, "mealPlannerThreads", newThreadId);
                    await setDoc(threadRef, {
                        threadId: newThreadId,
                        messages: newMessagesToStore,
                        recipes: recipesToStore, // Store the recipes in the thread document
                        createdAt: new Date()
                    });

                    setThreads(prevThreads => [{ id: newThreadId, threadId: newThreadId, messages: newMessagesToStore, recipes: recipesToStore, createdAt: new Date() }, ...prevThreads]);
                } else {
                    const threadDoc = doc(db, "users", currentUser.uid, "mealPlannerThreads", threadId);
                    await updateDoc(threadDoc, {
                        messages: arrayUnion(...newMessagesToStore),
                        recipes: recipesToStore // Update the recipes in the thread document
                    });

                    setThreads(prevThreads => {
                        const updatedThreads = prevThreads.map(thread => {
                            if (thread.threadId === threadId) {
                                return {
                                    ...thread,
                                    messages: [...thread.messages, ...newMessagesToStore],
                                    recipes: recipesToStore // Update the recipes in the thread state
                                };
                            }
                            return thread;
                        });
                        return updatedThreads;
                    });
                }

                FirebaseAnalytics.logEvent({
                    name: "chat_with_ai_message",
                    params: { aiMessageNumber: messages.length, aiThreadId: response.data[0].thread_id }
                });
            }
        } catch (error) {
            console.error('Error sending message:', error);
            setError('Failed to send message. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    const handleTabClick = (selectedThreadId) => {
        const selectedThread = threads.find(thread => thread.threadId === selectedThreadId);
        setThreadId(selectedThreadId);
        setMessages(selectedThread.messages);
        setRecipes(selectedThread.recipes || []); // Load the stored recipes if available
    };

    const handleCreateNewThread = () => {
        setThreadId(null);
        setMessages([{ content: 'Hello, how may I help you? You can ask me for example: \n * Plan a vegan mediterranean dinner \n * Recommend a breakfast that is high on protein and around 500 calories \n \n Note if you need help adjusting your macros consult with the [dietary consultant](/ai_consultation)', role: 'assistant' }]);
        setRecipes([]); // Reset recipes for the new thread
    };

    const handleDeleteThread = async (threadIdToDelete) => {
        await deleteDoc(doc(db, "users", currentUser.uid, "mealPlannerThreads", threadIdToDelete));
        setThreads(prevThreads => prevThreads.filter(thread => thread.threadId !== threadIdToDelete));
        if (threadId === threadIdToDelete) {
            setThreadId(null);
            setMessages([]);
            setRecipes([]); // Clear recipes when the thread is deleted
        }
    };

    const handleDialogOpen = () => {
        setDialogOpen(true);
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    return (
        <div className="chat-container">
            <div className="chat-top-message" style={{ margin: '8px auto 15px auto' }}>AI Meal Planner (Beta)</div>
            <div className="chat-top-message-secondary" style={{ width: '250px' }}>Describe the foods you want to cook and let AI make a recommendation</div>

            <div className="tabs-container-wrapper">
                <div className="tabs-container">
                    {threads.map((thread) => (
                        <div key={thread.id} className="tab-container">
                            <div className={`tab ${thread.threadId === threadId ? 'active' : ''}`}>
                                <button style={{ background: 'none', border: 'none', color: 'white' }} onClick={() => handleTabClick(thread.threadId)}>
                                    {new Date(thread.createdAt).toLocaleDateString()}
                                </button>
                                <button style={{ background: 'none', border: 'none', color: 'white', marginLeft: '0px', marginRight: '-5px' }} onClick={() => handleDeleteThread(thread.threadId)}>X</button>
                            </div>
                        </div>
                    ))}
                </div>
                <button className="new-thread-button" onClick={handleCreateNewThread}>+</button>
            </div>

            <div className="chat-box" style={{ height: recipes.length > 0 ? 'calc(100dvh - 395px)' : 'calc(100dvh - 325px)' }}>
                {messages.map((msg, index) => (
                    <div key={index} className={`chat-message ${msg.role}`}>
                        <ReactMarkdown>{msg.content}</ReactMarkdown>
                    </div>
                ))}
                {error && <div className="chat-error">{error}</div>} {/* Display error message */}
            </div>

            {recipes.length > 0 && (
                <div className="recipe-preview-row" onClick={handleDialogOpen}>
                    {recipes.map((recipe, index) => (
                        <img
                            key={index}
                            src={recipe.picture}
                            alt={recipe.name}
                            className="recipe-preview-image"
                        />
                    ))}
                </div>
            )}

            <div className="chat-input-container">
                <input
                    type="text"
                    value={input}
                    onChange={(e) => setInput(e.target.value)}
                    onKeyPress={(e) => e.key === 'Enter' ? handleSendMessage() : null}
                    className="chat-input"
                    placeholder="Type your message..."
                    disabled={loading}
                />
                <button onClick={handleSendMessage} className="send-button" disabled={loading}>
                    {loading ? <>Sending <LoopIcon style={{ animation: 'spin 2s linear infinite', marginLeft: '2px', fontSize: '20px' }} /></> : 'Send'}
                </button>
            </div>

            <Dialog open={dialogOpen} onClose={handleDialogClose} maxWidth="md" style={{ padding: '0px' }} >
                <DialogTitle style={{ textAlign: 'center' }}>Recommended Recipes</DialogTitle>
                <AIPlanning threadId={threadId} setTabValue={props.setTabValue} recipes={recipes} setRecipes={setRecipes}></AIPlanning>
                <DialogActions>
                    <Button onClick={handleDialogClose} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default Chat;
