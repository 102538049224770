import React from "react";
import { CartContext } from 'contexts/cart_context';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import { config } from 'Constants'
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import FavoriteIcon from '@material-ui/icons/Favorite';
import { pink } from '@material-ui/core/colors';

import _ from "lodash";
import { getMealNutrientAmount } from "helpers/recipe_helpers";
import { Switch, withStyles } from "@material-ui/core";


const PurpleSwitch = withStyles({
    switchBase: {
      color: pink[100],
      '&$checked': {
        color: pink[300],
      },
      '&$checked + $track': {
        backgroundColor: pink[300],
      },
    },
    checked: {},
    track: {
        backgroundColor: pink[100],

    },
  })(Switch);

const CalendarAddCustomMeal = (props) => {
    const addMealToColumn = React.useContext(CartContext).addOrMoveMealToColumn;

    const [mealObject, setMealObject] = React.useState(props.calendarCategory ? { ...config.emptyRecipe, calendarCategory: props.calendarCategory } : { ...config.emptyRecipe, calendarCategory: "breakfast" });
    const [addingToFavorites,setAddingToFavorites] = React.useState(true)
    const addFavoriteCustomLog= React.useContext(CartContext).addFavoriteCustomLog;

    const handleTextChange = (e) => {
        setMealObject({ ...mealObject, [e.target.name]: e.target.value })
    }


    const handleCalorieChange = (e) => {

        let mealObjectClone = _.cloneDeep(mealObject)
        for (let mealNutrient of mealObjectClone.nutrition.nutrients) {

            if (mealNutrient.name === "Calories") {
                mealNutrient['amount'] = e.target.value
            }
        }



        setMealObject(mealObjectClone)
    }


    const handleMacroChange = (e) => {
        let calculatedCalories;

        if (e.target.name === "Fat") {
            calculatedCalories = e.target.value * 9 + getMealNutrientAmount(mealObject, "Carbohydrates") * 4 + getMealNutrientAmount(mealObject, "Protein") * 4
        }

        if (e.target.name === "Carbohydrates") {
            calculatedCalories = e.target.value * 4 + getMealNutrientAmount(mealObject, "Fat") * 9 + getMealNutrientAmount(mealObject, "Protein") * 4
        }

        if (e.target.name === "Protein") {
            calculatedCalories = e.target.value * 4 + getMealNutrientAmount(mealObject, "Fat") * 9 + getMealNutrientAmount(mealObject, "Carbohydrates") * 4
        }

        let mealObjectClone = _.cloneDeep(mealObject)
        for (let mealNutrient of mealObjectClone.nutrition.nutrients) {
            if (mealNutrient.name === e.target.name) {
                mealNutrient['amount'] = e.target.value
            }
            if (mealNutrient.name === "Calories") {
                mealNutrient['amount'] = calculatedCalories
            }
        }



        setMealObject(mealObjectClone)
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        let mealObjectClone = { ...mealObject, areNutrientsManual: true }
        addMealToColumn(props.calendarDate, mealObjectClone, mealObject['calendarCategory'])
        if(addingToFavorites){
            addFavoriteCustomLog(mealObjectClone)
        }

        props.handleExitClick()
    }


    return (<div className="custom-meal-form" style={{ textAlign: 'center', width: 'calc(100vw - 40px)', maxWidth: '500px' }}>
        <div className="custom-meal-form-top-message">Input meal information </div>
        <form onSubmit={handleSubmit}>
            {/* 
            <div className="custom-meal-form-line-item">
                <Select labelId="demo-simple-select-label" name='calendarCategory' id="demo-simple-select" value={mealObject.calendarCategory} onChange={handleTextChange}>
                    <MenuItem value={"breakfast"}>Breakfast</MenuItem>
                    <MenuItem value={"lunch"}>Lunch</MenuItem>
                    <MenuItem value={"dinner"}>Dinner</MenuItem>
                    <MenuItem value={"snack"}>Snack</MenuItem>
                </Select>
            </div> */}

            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', margin: '10px 0px' }}>
                <TextField className="calorie-box-text-field-wide" style={{ width: '150px !important' }} type="text" variant="filled" required={true} label="" name='name' value={mealObject.name} onChange={handleTextChange}></TextField>
                <span className="calorie-box-label-narrow" style={{ background: '#000000' }}> Name</span>
            </div>


            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', margin: '10px 0px' }}><TextField className="calorie-box-text-field-medium protein" InputProps={{ endAdornment: <InputAdornment position="end">g</InputAdornment>, 'aria-label': 'naked' }} 
            type="number" variant="filled" label="" name='Protein' value={getMealNutrientAmount(mealObject, "Protein")=== 0 ? "" : getMealNutrientAmount(mealObject, "Protein")} onChange={handleMacroChange}></TextField>
                <span className="calorie-box-label-medium protein-background">Protein</span></div>

            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', margin: '10px 0px' }}> <TextField className="calorie-box-text-field-medium carbs" InputProps={{ endAdornment: <InputAdornment position="end">g</InputAdornment>, 'aria-label': 'naked' }} 
            type="number" variant="filled" label="" name='Carbohydrates' value={getMealNutrientAmount(mealObject, "Carbohydrates")=== 0 ? "" : getMealNutrientAmount(mealObject, "Carbohydrates")} onChange={handleMacroChange}></TextField>
                <span className="calorie-box-label-medium carbs-background">Carbs</span></div>

            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', margin: '10px 0px' }}><TextField className="calorie-box-text-field-medium fat" InputProps={{ endAdornment: <InputAdornment position="end">g</InputAdornment>, 'aria-label': 'naked' }} 
            type="number" variant="filled" label="" name='Fat' value={getMealNutrientAmount(mealObject, "Fat") === 0 ? "" : getMealNutrientAmount(mealObject, "Fat")} onChange={handleMacroChange}></TextField>
                <span className="calorie-box-label-medium fat-background">Fat</span></div>

            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', margin: '10px 0px' }}><TextField className="calorie-box-text-field-medium calories" variant="filled" type="number" name='Calories' 
            value={getMealNutrientAmount(mealObject, "Calories")=== 0 ? "" : getMealNutrientAmount(mealObject, "Calories")} onChange={handleCalorieChange}></TextField>
                <span className="calorie-box-label-medium calories-background">Calories</span></div>
                <div> Save as favorite?
                    
               
              <PurpleSwitch style={{}} checked={addingToFavorites} onChange={()=>{setAddingToFavorites(!addingToFavorites)}}></PurpleSwitch> 
              {addingToFavorites?<FavoriteIcon style={{ marginBottom:'-7px', color: '#ff7c93' }} />:<FavoriteBorderIcon style={{ marginBottom:'-7px', color: 'grey' }} />}

           
       
    

                     </div>
            <div className="custom-meal-form-submit-button"><button type="submit" className="order-ingredients-button-for-browser" style={{ padding: '10px 20px', fontSize: '15px', fontFamily: 'Montserrat-SemiBold' }} variant="contained" color="primary" value="Submit" >Add to calendar</button></div>
        </form>
    </div>)

}

export default CalendarAddCustomMeal;