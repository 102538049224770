import React from 'react';
import { Button, Checkbox, Tooltip } from '@material-ui/core'
//  import FoodSearchRow from './branded_product_search_row';
import { config } from 'Constants';
import _ from 'lodash';
import { CartContext } from 'contexts/cart_context';

import MealPortioningSlider from '../meal_portioning_slider/meal_portioning_slider';

import FavoriteIcon from '@material-ui/icons/Favorite';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import { v4 as uuidv4 } from 'uuid';


const AITrackingRow = (props) => {
    const [foodId, setFoodId] = React.useState(null)
    const addFavoriteCustomLog = React.useContext(CartContext).addFavoriteCustomLog;
    const removeFoodFromFavorites = React.useContext(CartContext).removeFoodFromFavorites;
    let food = props.food
    let foodList = props.foodList
    let setFoodList = props.setFoodList
    let index = props.index
    const favoriteFoodList = React.useContext(CartContext).favoriteFoodList


    function setPortion(portion, index) {
        let foodListClone = foodList.concat([])
        foodListClone[index].portionChosen = portion
        setFoodList(foodListClone)
    }

    const removeFromFavorites = (e) => {
        removeFoodFromFavorites(foodId)
    }
    const addToFavorites = (e) => {
        let id = uuidv4()
        setFoodId(id)
        let mealObject = _.cloneDeep(config.emptyRecipe)
        mealObject = props.calendarCategory ? { ...mealObject, calendarCategory: props.calendarCategory } : { ...mealObject, calendarCategory: "breakfast" }

        mealObject = { ...mealObject, food_id: id, portion: food.portionChosen, name: food.name, description: "Made by AI - a portion is " + food.macros.portionInGrams + "g", areNutrientsManual: true,
        picture: `https://storage.googleapis.com/z-com-299512.appspot.com/App/${food.foodCategory}.png`
    }

        for (let mealNutrient of mealObject.nutrition.nutrients) {
            if (mealNutrient.name === "Calories") {
                mealNutrient['amount'] = Math.round(food.macros.calories * food.portionChosen)
            }
            if (mealNutrient.name === "Protein") {
                mealNutrient['amount'] = Math.round(food.macros.protein * food.portionChosen)
            }
            if (mealNutrient.name === "Carbohydrates") {
                mealNutrient['amount'] = Math.round(food.macros.carbohydrates * food.portionChosen)
            }
            if (mealNutrient.name === "Fat") {
                mealNutrient['amount'] = Math.round(food.macros.fat * food.portionChosen)
            }
            if (mealNutrient.name === "Cholesterol") {
                mealNutrient['amount'] = Math.round(food.macros.cholesterol * food.portionChosen)
            }
            if (mealNutrient.name === "Sugar") {
                mealNutrient['amount'] = Math.round(food.macros.sugars * food.portionChosen)
            }
            if (mealNutrient.name === "Sodium") {
                mealNutrient['amount'] = Math.round(food.macros.sodium * food.portionChosen)
            }
            if (mealNutrient.name === "Net Carbohydrates") {
                mealNutrient['amount'] = Math.round(food.macros['net carbohydrates'] * food.portionChosen)
            }
            if (mealNutrient.name === "Saturated Fat") {
                mealNutrient['amount'] = Math.round(food.macros['saturated fat'] * food.portionChosen)
            }
            if (mealNutrient.name === "Fiber") {
                mealNutrient['amount'] = Math.round(food.macros.fiber * food.portionChosen)
            }
        }
        addFavoriteCustomLog(mealObject, false)
    }

    function toggleChecked() {
        let foodListClone = foodList.concat([])
        foodListClone[index].checked = !foodListClone[index].checked
        setFoodList(foodListClone)

    }

    let foodInFavorites = false
    if (foodId) {
        for (let favoriteFoodItem of favoriteFoodList) {
            if (favoriteFoodItem.food_id === foodId){
                foodInFavorites = true
                break
            }
    }
    }


    return (
        <div style={{ borderBottom: '1px solid #e1e1e1' }}>
            <div key={`calendar-add-menu-meal-row-${food.food_id}`} className="calendar-add-menu-meal-row" style={{ marginBottom: '10px', maxWidth: '100%' }}>

                <Checkbox className="ai-tracking-form-checkbox" style={{ marginRight: '10px' }} checked={food.checked} onChange={toggleChecked}></Checkbox>

                <div className="calendar-add-menu-meal-column" style={{ overflow: 'hidden' }} >
                    <div style={{ display: 'flex', maxWidth: '100%' }}>
                        <div className="calendar-add-menu-meal-name" style={{ margin: '7px 10px 7px 0px' }}>{food.name}
                        </div>
                    </div>
                    <div className="food-search-box-inner-row">

                        <div >

                            <div className="ai-tracking-add-custom-meal-caloric-info" style={{ marginBottom: '7px' }}>
                                <span className="caloric-chip caloric-chip-calories" style={{fontSize:'10px'}}> {Math.round(food.macros.calories * food.portionChosen)} Cals</span>
                                <span className="caloric-chip caloric-chip-protein">{Math.round(food.macros.protein * food.portionChosen)}g</span>
                                <span className="caloric-chip caloric-chip-carbs">{Math.round(food.macros.carbohydrates * food.portionChosen)}g</span>
                                <span className="caloric-chip caloric-chip-fats">{Math.round(food.macros.fat * food.portionChosen)}g</span>
                                <span style={{ marginLeft: '6px', fontSize: '12px', fontFamily: 'Montserrat-Medium', color: '#b4b4b4' }}> {Math.round(food.macros.portionInGrams * food.portionChosen)}g</span>

                            </div>




                            <div style={{ display: 'flex', alignItems: 'center', fontSize: '13px' }}>
                                Servings: <span style={{ marginLeft: '10px', marginBottom: '-6px', width: '400px' }}><MealPortioningSlider portion={food.portionChosen} setPortion={setPortion} index={index} shrink={true} smallPopup={true} /></span>
                            </div>
                        </div>


                    </div>


                </div>
                <div className="calendar-add-menu-meal-column" style={{ maxWidth: '100px' }}>
                    {foodInFavorites ? <Tooltip title="Remove from favorites">
                        <Button style={{ color: '#ff7c93' }} value={index} onClick={removeFromFavorites}><FavoriteIcon /></Button>
                    </Tooltip> :
                        <Tooltip title="Add to favorites">
                            <Button style={{ color: 'grey' }} value={index} onClick={addToFavorites}><FavoriteBorderIcon /></Button>
                        </Tooltip>
                    }
                </div>
            </div>

        </div>



    )
}
export default AITrackingRow