import React, { useState } from 'react';
import './add_recipe_to_plan.css';  // Import the CSS file
import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics';
import _ from 'lodash';
import { AuthContext } from 'contexts/auth_context';
import { config } from "Constants";
import { CartContext } from 'contexts/cart_context';
import { UserContext } from 'contexts/user_context';
import MealPortioningSlider from '../meal_portioning_slider/meal_portioning_slider';

const getWeeks = (numWeeks = 5, pastWeeks = 0) => {
    const weeks = [];
    const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const months = [
        'January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December'
    ];

    const getDaySuffix = (date) => {
        const dateNumber = date.getDate();
        if (dateNumber >= 11 && dateNumber <= 13) {
            return 'th';
        }
        switch (dateNumber % 10) {
            case 1: return 'st';
            case 2: return 'nd';
            case 3: return 'rd';
            default: return 'th';
        }
    };

    const formatDate = (date) => {
        const day = daysOfWeek[date.getDay()];
        const dateNumber = date.getDate();
        const suffix = getDaySuffix(date);
        const month = months[date.getMonth()];
        return `${day} ${dateNumber}${suffix} of ${month}`;
    };

    let currentDate = new Date();

    // Get past weeks
    for (let i = pastWeeks; i > 0; i--) {
        let startOfWeek = new Date(currentDate);
        startOfWeek.setDate(startOfWeek.getDate() - startOfWeek.getDay() - (i * 7) + 1);

        let endOfWeek = new Date(startOfWeek);
        endOfWeek.setDate(endOfWeek.getDate() + 6);

        weeks.push({
            label: `${formatDate(startOfWeek)} to ${formatDate(endOfWeek)}`,
            startOfWeek: startOfWeek.toISOString().split('T')[0],
            endOfWeek: endOfWeek.toISOString().split('T')[0]
        });
    }

    // Get future weeks
    for (let i = 0; i < numWeeks; i++) {
        let startOfWeek = new Date(currentDate);
        startOfWeek.setDate(startOfWeek.getDate() - startOfWeek.getDay() + 1 + (i * 7));

        let endOfWeek = new Date(startOfWeek);
        endOfWeek.setDate(endOfWeek.getDate() + 6);

        weeks.push({
            label: `${formatDate(startOfWeek)} to ${formatDate(endOfWeek)}`,
            startOfWeek: startOfWeek.toISOString().split('T')[0],
            endOfWeek: endOfWeek.toISOString().split('T')[0]
        });
    }

    return weeks;
};






const AddRecipeToPlan = (props) => {
    const addRecipeToCookingList = React.useContext(CartContext).addRecipeToCookingList;
    const { currentUser } = React.useContext(AuthContext)
    const [selectedWeek, setSelectedWeek] = useState(getWeeks()[0].startOfWeek);
    const setUserObject = React.useContext(UserContext).setUserObject;
    const userObject = React.useContext(UserContext).userObject
    const [portion, setPortion] = React.useState(props.recipe?.portion? props.recipe.portion : 1)
    const weeks = getWeeks();


    const handleWeekChange = (event) => {
        setSelectedWeek(event.target.value);
    };

    const handleSubmit = async () => {
        if (!selectedWeek) {
            alert('Please select a week.');
            return;
        }

        if (!props.recipe.recipeBooks) {
            //web recipes need to be processed
            let recipeClone = await props.prepareWebRecipe(props.recipe)
            addRecipeToCookingList({ ...recipeClone, week: selectedWeek })
            FirebaseAnalytics.logEvent({
                name: "added_recipe_to_cooking_list",
                params: { recipe_name: recipeClone.name, currentUser: currentUser.uid, recipe_type: 'web' }
            })
        }
        else {
            //if not web
            addRecipeToCookingList(_.cloneDeep({ ...props.recipe, week: selectedWeek, portion: portion}))
            FirebaseAnalytics.logEvent({
                name: "added_recipe_to_cooking_list",
                params: { recipe_name: props.recipe.name, currentUser: currentUser.uid, recipe_type: props.recipe.recipeBooks[0] === config.baseRecipeBookId ? "bitetracker" : "user" }
            })
        }
        props.setShowIngredientsChip && props.setShowIngredientsChip(true)
        props.setShowWeeklyPlanChip && props.setShowWeeklyPlanChip(true)
        setUserObject({ ...userObject, addMealTabValue: 0 })

        props.exitDialog()

    }

    return (
        <div className="container">
            <div style={{ textAlign: 'center', fontFamily: 'Montserrat-SemiBold', padding: '25px 0px' }}>For which week do you want to cook?</div>
            <div className="select-container">
                <select value={selectedWeek} onChange={handleWeekChange}>
                    <option value="" disabled>Select a week</option>
                    {weeks.map((week, index) => (
                        <option key={index} value={week.startOfWeek}>
                            {week.label}
                        </option>
                    ))}
                </select>
            </div>
{/* 
            <div style={{ textAlign: 'center', fontFamily: 'Montserrat-SemiBold', padding: '10px 0px' }}>Choose Servings</div>

            <MealPortioningSlider portion={portion} setPortion={setPortion} meal={null} setCartMeal={()=>{}} /> */}
            <button style={{marginTop:'5px'}} className={!selectedWeek ? "cancel-button" : "submit-button"} disabled={!selectedWeek} onClick={handleSubmit}>Add Recipe to Plan</button>
        </div>
    );
};

export default AddRecipeToPlan;
