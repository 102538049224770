import React from "react";
import './plan_recipes.css'

import Button from '@material-ui/core/Button';
import { RecipeBooksContext } from "contexts/recipe_books_context";
import { FixedSizeList } from "react-window";
import { Dialog, TextField } from "@material-ui/core";
import CreateNewRecipe from "../create_new_recipe/create_new_recipe";
import { checkImageValidity, cleanUpNutrients, getMealNutrientAmount } from "helpers/recipe_helpers";
import _ from "lodash";
import LoopIcon from '@material-ui/icons/Loop';
import FilterIcon from "images/filters_icon.png";
import Filters from "../filters/filters";
import { useDebouncedCallback } from 'use-debounce';
import { AuthContext } from 'contexts/auth_context';
import ModifyRecipe from "../modify_recipe/modify_recipe";
import MealDetailsCard from "../meal_details_card/meal_details_card";
import { config } from "Constants";
import { KeyboardArrowDown, KeyboardArrowUp } from "@material-ui/icons";
import CopyRecipeDialog from "../modify_recipe/copy_recipe_dialog";
import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics';
import axios from "axios";
import AddRecipeToPlan from "../add_recipe_to_plan/add_recipe_to_plan";

const Row = ({ data, index, style }) => {
    let meal = data.userFilteredRecipes ? data.userFilteredRecipes[index] : data.biteTrackerFilteredRecipes ? data.biteTrackerFilteredRecipes[index] : data.webFilteredRecipes[index]
    let handleRecipeAddClick = data.handleRecipeAddClick ? data.handleRecipeAddClick : data.handleWebRecipeAddClick
    let getCustomRecipeBookId = data.getCustomRecipeBookId
    let handleEditRecipeClick = data.handleEditRecipeClick
    let handleRecipeViewClick = data.handleRecipeViewClick ? data.handleRecipeViewClick : data.handleWebRecipeViewClick
    let handleRecipeCopyClick = data.handleRecipeCopyClick ? data.handleRecipeCopyClick : data.handleWebRecipeCopyClick

    let pictureURL = meal.picture.replace("https://storage.googleapis.com/thymeless-upload-storage/", "https://ik.imagekit.io/hk6gff5az/tr:w-150,h-150/")

    return (
        <div key={`calendar-add-menu-meal-row-${meal.id}`} style={style} className="plan-recipes-row" >
            <img className="plan-recipes-thumbnail" value={meal.id ? meal.id : meal.spoonacularRecipeId} onClick={handleRecipeViewClick} src={pictureURL} alt={meal.name} />
            <div className="plan-recipes-info">
                <div className="plan-recipes-name-outer">
                    <div onClick={handleRecipeViewClick} value={meal.id ? meal.id : meal.spoonacularRecipeId} className="plan-recipes-name">{meal.name}</div>
                </div>
                {(!!meal.aggregateLikes && !meal.recipeBooks) && <div className="plan-recipes-likes"> {meal.aggregateLikes} like{meal.aggregateLikes > 1 && "s"}</div>}
                {getMealNutrientAmount(meal, "Calories") > 0 && <div className="plan-recipes-nutrition">
                    <span className="caloric-chip caloric-chip-calories">{getMealNutrientAmount(meal, "Calories")} Cals</span>
                    <span className="caloric-chip caloric-chip-protein">{getMealNutrientAmount(meal, "Protein")}g</span>
                    <span className="caloric-chip caloric-chip-carbs">{getMealNutrientAmount(meal, "Carbohydrates")}g</span>
                    <span className="caloric-chip caloric-chip-fats">{getMealNutrientAmount(meal, "Fat")}g</span>
                </div>}
                <div>
                    <button className="plan-recipes-add-button" variant="contained" color="primary" value={meal.id ? meal.id : meal.spoonacularRecipeId} onClick={handleRecipeAddClick}>Add</button>
                    {(meal.recipeBooks && meal.recipeBooks.includes(getCustomRecipeBookId())) ? <button className="plan-recipes-add-button" style={{ background: 'rgb(129 197 36)' }} variant="contained" color="primary" value={meal.id} onClick={handleEditRecipeClick}>Edit</button> :
                        <button className="plan-recipes-add-button" style={{ background: '#E6EFDA', color: '#5e8b20' }} variant="contained" color="primary" value={meal.id ? meal.id : meal.spoonacularRecipeId} onClick={handleRecipeCopyClick}>Save</button>}
                </div>
            </div>
        </div>
    )
};


const PlanRecipes = (props) => {
    const recipes = React.useContext(RecipeBooksContext).recipeList;

    const [recipeFilterText, setRecipeFilterText] = React.useState("")
    const [showFilters, setShowFilters] = React.useState(false)
    const [filters, setFilters] = React.useState({})
    const [mealCategoriesFiltered, setMealCategoriesFiltered] = React.useState("")
    const [recipeBooksFiltered, setRecipeBooksFiltered] = React.useState("")
    const [timeFiltered, setTimeFiltered] = React.useState([0, 300])
    const { currentUser } = React.useContext(AuthContext)
    const getCustomRecipeBookId = React.useContext(RecipeBooksContext).getCustomRecipeBookId
    const [recipeBeingModified, setRecipeBeingModified] = React.useState(null)
    const [recipeBeingCopied, setRecipeBeingCopied] = React.useState(null)
    const [recipeBeingAdded, setRecipeBeingAdded] = React.useState(null)
    const [recipeBeingViewed, setRecipeBeingViewed] = React.useState(null)
    const [showUserRecipes, setShowUserRecipes] = React.useState(true)
    const [showBiteTrackerRecipes, setShowBiteTrackerRecipes] = React.useState(true)
    const [showWebRecipes, setShowWebRecipes] = React.useState(true)
    const myRecipesRef = React.useRef(null);
    const [webRecipeList, setWebRecipeList] = React.useState([])
    const [webRecipesLoading, setWebRecipesLoading] = React.useState(false)
    const [biteTrackerRecipesFromSearch, setBiteTrackerRecipesFromSearch] = React.useState([])

    React.useEffect(() => {
        if (recipeFilterText !== "") {
            setWebRecipeList([]);
            setBiteTrackerRecipesFromSearch([]);
            setWebRecipesLoading(true);

            const searchRecipes = setTimeout(async () => {
                const resp = await axios.get('/recipe/search-v2/' + recipeFilterText);
                let webRecipes = [];
                if (resp.data && resp.data.webRecipes) {
                    for (let webRecipe of resp.data.webRecipes) {
                        if (webRecipe.analyzedInstructions.length === 0) { continue; }
                        if (webRecipe.servings > 8) { continue; }
                        let doesImageWork = await checkImageValidity(webRecipe.image);
                        webRecipes.push({
                            name: webRecipe.title, spoonacularRecipeId: webRecipe.id, description: 'From ' + webRecipe.sourceName + ' - designed for ' + webRecipe.servings + ' portion(s)', analyzedInstructions: webRecipe.analyzedInstructions[0],
                            nutrition: webRecipe.nutrition, type: 'web-recipe', picture: doesImageWork ? webRecipe.image : config.basePictureURL, aggregateLikes: webRecipe.aggregateLikes,
                            ingredients: webRecipe.extendedIngredients, portion: webRecipe.servings, tags: [], categories: [], readyInMinutes: webRecipe.readyInMinutes
                        });
                    }
                }

                if (resp.data && resp.data.BTRecipes) {
                    setBiteTrackerRecipesFromSearch(resp.data.BTRecipes);
                }

                setWebRecipeList(webRecipes);
                setWebRecipesLoading(false);
            }, 500);

            return () => clearTimeout(searchRecipes);
        }
        else{
            setWebRecipesLoading(false)
            setWebRecipeList([]);
            setBiteTrackerRecipesFromSearch([]);
        }
    }, [recipeFilterText]);

    const scrollToTopOfMyRecipes = () => {
        myRecipesRef.current.scrollIntoView({
            behavior: 'smooth',
            block: 'nearest'
        });

        setShowUserRecipes(true)
    };

    const toggleShowUserRecipes = () => {
        setShowUserRecipes(!showUserRecipes)
    }

    const toggleShowWebRecipes = () => {
        setShowWebRecipes(!showWebRecipes)
    }

    const toggleShowBiteTrackerRecipes = () => {
        setShowBiteTrackerRecipes(!showBiteTrackerRecipes)
    }

    const handleExitClick = () => {
        setRecipeBeingModified(null)
    }

    const handleViewingExitClick = () => {
        setRecipeBeingViewed(null)
    }

    const handleEditRecipeClick = (e) => {
        let foundRecipe = recipes.find(r => r.id === e.currentTarget.value)
        setRecipeBeingModified(foundRecipe)
    }

    const handleRecipeViewClick = (e) => {
        let foundRecipe = recipes.find(r => r.id === e.currentTarget.getAttribute('value'))
        setRecipeBeingViewed(foundRecipe)
    }

    const handleRecipeCopyClick = (e) => {
        let foundRecipe = recipes.find(r => r.id === e.currentTarget.value)
        setRecipeBeingCopied(foundRecipe)
    }

    const handleRecipeAddClick = (e) => {
        let foundRecipe = recipes.find(r => r.id === e.currentTarget.value)
        setRecipeBeingAdded(foundRecipe)
    }

    const handleWebRecipeViewClick = (e) => {
        let foundRecipe = webRecipeList.find(r => r.spoonacularRecipeId === parseInt(e.currentTarget.getAttribute('value')))
        for (let ingredient of foundRecipe.ingredients) {
            ingredient.selectedUnit = ingredient.unit
            ingredient.possibleUnits = [ingredient.unit]
            ingredient.amountString = (Math.round(ingredient.amount * 100) / 100).toString()
        }
        setRecipeBeingViewed(foundRecipe)
    }

    const handleWebRecipeCopyClick = (e) => {
        let foundRecipe = webRecipeList.find(r => r.spoonacularRecipeId === parseInt(e.currentTarget.value))
        setRecipeBeingCopied(foundRecipe)
    }

    const handleWebRecipeAddClick = (e) => {
        let foundRecipe = webRecipeList.find(r => r.spoonacularRecipeId === parseInt(e.currentTarget.value))
        setRecipeBeingAdded(foundRecipe)
    }

    const handleEditRecipeClickInDetailsCard = (recipe) => {
        setTimeout(() => {
            setRecipeBeingViewed(null)
        }, 150)
        setRecipeBeingModified(recipe)
    }

    const debouncedLogFilterEvent = useDebouncedCallback(
        async (filteredText) => {
            try {
                if (filteredText.length >= 3) {
                    FirebaseAnalytics.logEvent({
                        name: "filtered_recipe",
                        params: { filtered_text: filteredText, currentUser: currentUser.uid }
                    })
                }
            }
            catch (error) {
                console.log(error)
            }
        },
        1000
    );

    const cleanUpMeasures = (ingredient, nutrition) => {
        let unitsToGrams = {
            'Tbsps': 14.175,
            'tbsp': 14.175,
            'Tbsp': 14.175,
            'tbsps': 14.175,
            'cups': 340,
            'tsps': 5.7,
            'tsp': 5.7,
            'cloves': 15,
            'large': 250,
            'medium': 200,
            'small': 150,
            'oz': 28.35,
            'packets': 10,
            'handfuls': 30
        }
        let foundUnit = false

        for (let unit of Object.keys(unitsToGrams)) {
            if (ingredient.measures.metric.unitShort === unit) {
                nutrition.weightPerServing = { amount: unitsToGrams[unit], unit: 'g' }
                foundUnit = true
                break
            }
            if (ingredient.measures.us.unitShort === unit) {
                nutrition.weightPerServing = { amount: unitsToGrams[unit], unit: 'g' }
                foundUnit = true
                break
            }
        }
        if (!foundUnit) {
            console.log("units not found: ", ingredient.measures.metric.unitShort, ingredient.measures.us.unitShort)
            nutrition.weightPerServing = { amount: 0.001, unit: 'g' }
        }
    }

    const prepareWebRecipe = async (recipe) => {
        let recipeClone = _.cloneDeep(recipe)
        const response = await axios.get('/recipe/ingredientNutrition/' + recipeClone.spoonacularRecipeId);
        for (let ingredientIndex in response.data.ingredients) {
            let extendedIngredient = recipeClone.ingredients[ingredientIndex]
            let ingredientNutrition = response.data.ingredients[ingredientIndex]

            extendedIngredient.selectedUnit = extendedIngredient.unit
            extendedIngredient.possibleUnits = [extendedIngredient.unit]
            extendedIngredient.amountString = (Math.round(extendedIngredient.amount * 100) / 100).toString()

            cleanUpMeasures(extendedIngredient, ingredientNutrition)

            ingredientNutrition.nutrients = cleanUpNutrients(ingredientNutrition.nutrients)

            let possibleUnits = ["g"]

            if (extendedIngredient.unit !== "g") {
                possibleUnits.push(extendedIngredient.unit)
            }
            extendedIngredient.possibleUnits = possibleUnits
            extendedIngredient.nutrition = ingredientNutrition
        }
        return recipeClone
    }

    const addMealFromDetailsCard = async (recipe) => {
        setRecipeBeingAdded(recipe)
    }

    const handleRecipeFilterTextChange = (event) => {
        setRecipeFilterText(event.target.value)
        debouncedLogFilterEvent(event.target.value)
    }

    const toggleShowFilters = () => {
        setShowFilters(!showFilters)
    }

    let userFilteredRecipes = []
    let biteTrackerFilteredRecipes = []
    let biteTrackerFilteredRecipesDescriptionMatch = []
    let webFilteredRecipes = webRecipeList
    let foundUserRecipe = false

    let filtersApplied = []

    if (recipes === null) {
        return <></>
    } else {
        for (let filterValue in filters) {
            if (filters[filterValue]) {
                filtersApplied.push(filterValue)
            }
        }

        if (mealCategoriesFiltered.length !== 0) {
            filtersApplied.push(mealCategoriesFiltered)
        }

        if (recipeBooksFiltered.length !== 0) {
            filtersApplied.push(recipeBooksFiltered)
        }

        for (let meal of recipes) {
            if (filtersApplied.length === 0 && mealCategoriesFiltered.length === 0 && recipeBooksFiltered.length === 0 &&
                recipeFilterText === "" && timeFiltered[0] === 0 && timeFiltered[1] === 300) {
                if (meal.recipeBooks[0] === config.baseRecipeBookId) {
                    biteTrackerFilteredRecipes.push(meal)
                } else {
                    userFilteredRecipes.push(meal)
                    foundUserRecipe = true
                }
            } else {
                let tags = meal.tags.concat(meal.categories).concat(meal.recipeBooks)
                let allTagsFound = true

                for (let filterValue in filtersApplied) {
                    if (tags === null) {
                        allTagsFound = false
                        break
                    }
                    if (!tags.includes(filtersApplied[filterValue])) {
                        allTagsFound = false
                    }
                }
                if (allTagsFound) {
                    if (!meal.readyInMinutes || (meal.readyInMinutes >= timeFiltered[0] && meal.readyInMinutes <= timeFiltered[1]))
                        if (meal.name.toLowerCase().includes(recipeFilterText.toLowerCase())) {
                            if (meal.recipeBooks[0] === config.baseRecipeBookId) {
                                biteTrackerFilteredRecipes.push(meal)
                                continue;
                            } else {
                                foundUserRecipe = true
                                userFilteredRecipes.push(meal)
                            }
                        }
                    if (meal.description.toLowerCase().includes(recipeFilterText.toLowerCase())) {
                        if (meal.recipeBooks[0] === config.baseRecipeBookId) {
                            biteTrackerFilteredRecipesDescriptionMatch.push(meal)
                        }
                    }
                }
            }
        }
    }

    for (let biteTrackerRecipeFromSearch of biteTrackerRecipesFromSearch) {
        let foundRecipe = false
        for (let biteTrackerFilteredRecipe of biteTrackerFilteredRecipes) {
            if (biteTrackerRecipeFromSearch.name === biteTrackerFilteredRecipe.name) {
                foundRecipe = true
            }
        }
        if (!foundRecipe) {
            biteTrackerFilteredRecipes.push(biteTrackerRecipeFromSearch)
        }
    }

    for (let biteTrackerRecipeDescriptionMatched of biteTrackerFilteredRecipesDescriptionMatch) {
        let foundRecipe = false
        for (let biteTrackerFilteredRecipe of biteTrackerFilteredRecipes) {
            if (biteTrackerRecipeDescriptionMatched.name === biteTrackerFilteredRecipe.name) {
                foundRecipe = true
            }
        }
        if (!foundRecipe) {
            biteTrackerFilteredRecipes.push(biteTrackerRecipeDescriptionMatched)
        }
    }

    return (
        <>
            <div className="plan-recipes-container">
                <div className="plan-recipes-header">
                    <div style={{maxWidth:'500px', display:'flex', width:'calc(100% - 20px)'}}>
                    <button className="plan-recipes-filter-button" onClick={toggleShowFilters}><img src={FilterIcon} alt="filter icon" style={{ width: '18px', marginTop: '4px', marginRight: '4px', filter: (filtersApplied.length === 0 && mealCategoriesFiltered.length === 0 && recipeBooksFiltered.length === 0) ? 'invert(47%) sepia(3%) saturate(6%) hue-rotate(344deg) brightness(100%) contrast(88%)' : ' brightness(0) saturate(100%) invert(36%) sepia(21%) saturate(1404%) hue-rotate(74deg) brightness(98%) contrast(86%)' }} /></button>
                    <TextField className="plan-recipes-filter-input" placeholder='Filter and search recipes...' label="" value={recipeFilterText} onChange={handleRecipeFilterTextChange} />
                    </div>
                </div>

                <div className="plan-recipes-content">
                    <div className="plan-recipes-section">
                        <div ref={myRecipesRef} className="plan-recipes-section-header" style={{marginBottom:'4px'}}><div onClick={toggleShowUserRecipes} >My Recipes </div>
                        <div style={{marginRight:'0px', marginLeft:'8px'}}><CreateNewRecipe setRecipeFilterText={setRecipeFilterText} /></div>
                        <div style={{marginRight:'auto', marginLeft:'5px'}}><CreateNewRecipe setRecipeFilterText={setRecipeFilterText} extractRecipe={true} /></div>

                            <Button onClick={toggleShowUserRecipes} className="plan-recipes-collapse-button">
                                {showUserRecipes ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                            </Button>
                        </div>

                        {recipes.length === 0 && <div className="plan-recipes-loading-message">Loading recipes <LoopIcon style={{ animation: 'spin 2s linear infinite', marginLeft: '5px', color: 'grey', fontSize: '16px' }} /></div>}

                        {!foundUserRecipe && showUserRecipes && recipes.length !== 0 && <div className="plan-recipes-no-recipes-message">
                            No recipes found. Create recipes or save BiteTracker/Web recipes
                        </div>}
                        {showUserRecipes && foundUserRecipe &&
                            <FixedSizeList
                                height={250}
                                width={window.innerWidth}
                                itemCount={userFilteredRecipes.length}
                                itemSize={190}
                                layout="horizontal"
                                itemData={{
                                    userFilteredRecipes,
                                    handleRecipeAddClick,
                                    getCustomRecipeBookId,
                                    handleEditRecipeClick,
                                    handleRecipeViewClick,
                                }}
                            >
                                {Row}
                            </FixedSizeList>
                        }
                    </div>
                    <div className="plan-recipes-section">
                        <div onClick={toggleShowBiteTrackerRecipes} className="plan-recipes-section-header">BiteTracker Basics
                            <Button className="plan-recipes-collapse-button">
                                {showBiteTrackerRecipes ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                            </Button>
                        </div>
                        {webRecipesLoading && <div className="plan-recipes-loading-message">Loading recipes <LoopIcon style={{ animation: 'spin 2s linear infinite', marginLeft: '5px', color: 'grey', fontSize: '16px' }} /></div>}

                        {recipes.length === 0 && <div className="plan-recipes-loading-message">Loading recipes <LoopIcon style={{ animation: 'spin 2s linear infinite', marginLeft: '5px', color: 'grey', fontSize: '16px' }} /></div>}

                        {showBiteTrackerRecipes && !webRecipesLoading && biteTrackerFilteredRecipes.length > 0 &&
                            < FixedSizeList
                                height={250}
                                width={window.innerWidth}
                                itemCount={biteTrackerFilteredRecipes.length}
                                itemSize={190}
                                layout="horizontal"
                                itemData={{
                                    biteTrackerFilteredRecipes,
                                    handleRecipeAddClick,
                                    getCustomRecipeBookId,
                                    handleRecipeViewClick,
                                    handleRecipeCopyClick
                                }}
                            >
                                {Row}
                            </FixedSizeList>
                        }
                        {biteTrackerFilteredRecipes.length === 0 && !webRecipesLoading && recipes.length !== 0 && <div className="plan-recipes-no-recipes-message">
                            No recipes found for search term
                        </div>}
                    </div>
                    <div className="plan-recipes-section">
                        <div onClick={toggleShowWebRecipes} className="plan-recipes-section-header">Web Recipes
                            <Button className="plan-recipes-collapse-button">
                                {showWebRecipes ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                            </Button>
                        </div>
                        {webRecipesLoading && <div className="plan-recipes-loading-message">Loading recipes <LoopIcon style={{ animation: 'spin 2s linear infinite', marginLeft: '5px', color: 'grey', fontSize: '16px' }} /></div>}
                        {webRecipeList.length === 0 && !webRecipesLoading && showWebRecipes && <div className="plan-recipes-no-recipes-message"> Type in search box to see web recipes</div>}

                        {showWebRecipes && webRecipeList.length!==0 && !webRecipesLoading &&
                            <FixedSizeList
                                height={270}
                                width={window.innerWidth}
                                itemCount={webRecipeList.length}
                                itemSize={190}
                                layout="horizontal"
                                itemData={{
                                    webFilteredRecipes,
                                    handleWebRecipeAddClick,
                                    getCustomRecipeBookId,
                                    handleWebRecipeViewClick,
                                    handleWebRecipeCopyClick
                                }}
                            >
                                {Row}
                            </FixedSizeList>
                        }
                    </div>
                </div>
            </div >
            <Dialog maxWidth={'xl'} open={Boolean(recipeBeingModified)} >
                {<ModifyRecipe recipeBook={getCustomRecipeBookId} recipeBeingModified={recipeBeingModified} close={handleExitClick} creatingNewRecipe={false} setCreatingNewRecipe={null} disableCopy={false} />}
            </Dialog>
            <Dialog open={Boolean(recipeBeingViewed)} maxWidth={'xl'} >
                <MealDetailsCard isReadOnly={true} handleEditRecipeClickInDetailsCard={handleEditRecipeClickInDetailsCard} prepareWebRecipe={prepareWebRecipe} addToPlanEnabled={true} portion={1} handleExitClick={handleViewingExitClick} meal={recipeBeingViewed} customAddMealFunction={addMealFromDetailsCard} />
            </Dialog>
            <Dialog open={Boolean(recipeBeingAdded)} onClose={() => { setRecipeBeingAdded(null) }} maxWidth={'xl'} >
                <AddRecipeToPlan setShowIngredientsChip={props.setShowIngredientsChip} setShowWeeklyPlanChip={props.setShowWeeklyPlanChip} recipe={recipeBeingAdded} exitDialog={() => { setRecipeBeingAdded(null); setRecipeBeingViewed(null) }} prepareWebRecipe={prepareWebRecipe} />
            </Dialog>
            <Dialog open={showFilters} onClose={toggleShowFilters} maxWidth={'xl'}>
                <div style={{ display: 'flex', padding: '15px 15px', maxWidth: '400px' }} className="plan-recipes-filters">
                    <Filters addTime={true} addComplexity={true} removeRecipeBooks={true} filters={filters} setFilters={setFilters} timeFiltered={timeFiltered} setTimeFiltered={setTimeFiltered}
                        mealCategoriesFiltered={mealCategoriesFiltered} setMealCategoriesFiltered={setMealCategoriesFiltered} recipeBooksFiltered={recipeBooksFiltered}
                        setRecipeBooksFiltered={setRecipeBooksFiltered} /></div>
            </Dialog>
            <CopyRecipeDialog setRecipeFilterText={setRecipeFilterText} prepareWebRecipe={prepareWebRecipe} attemptedCopy={Boolean(recipeBeingCopied)} setAttemptedCopy={(value) => { setRecipeBeingCopied(null) }} recipe={recipeBeingCopied} scrollToTopOfRecipeBookList={scrollToTopOfMyRecipes} />
        </>
    )
}

export default PlanRecipes;
