import './onboarding_wizard.css'
import React from 'react';
import freeIcon from 'images/free-icon.png'
import saveTimeIcon from 'images/save-time-icon.png'
import ideasIcon from 'images/ideas-icon.png'
import calendarOnboarding from 'images/calendar-onboarding.png'
import takeAwayOnboarding from 'images/take-away-rafiki-2.png'

import { Button } from '@material-ui/core';
import CalorieCalculator from '../calorie_calculator/calorie_calculator';
// import { AuthContext } from 'contexts/auth_context';
import CalorieBox from '../modify_calories/calorie_box';
import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics';
import { UserContext } from "contexts/user_context";
import DietaryPreferences from '../dietary_preferences/dietary_preferences';
import { useHistory } from 'react-router-dom';


const OnboardingWizard = (props) => {
    const [onboardingStage, setOnboardingStage] = React.useState(0)
    // const { currentUser } = React.useContext(AuthContext)
    const [isTracking, setIsTracking] = React.useState(true)
    const [skipped, setSkipped] = React.useState(false)
    const userObject = React.useContext(UserContext).userObject
    const setUserObject = React.useContext(UserContext).setUserObject;
    let history = useHistory();

    function handleFirstClick() {
        setOnboardingStage(1)
    }

    function handleNextClick() {
        setOnboardingStage(2)
    }

    // function handleSubmitClick(){
    //     history.push("/food_menu");
    // }

    function handleTrackingOptionClick() {
        FirebaseAnalytics.logEvent({
            name: "chose_onboarding_option",
            params: { chosenOnboarding: 'tracking' }
        })



        setUserObject({ ...userObject, addMealTabValue: 1 })
        setOnboardingStage(4)
    }

    function handlePlanningOptionClick() {
        FirebaseAnalytics.logEvent({
            name: "chose_onboarding_option",
            params: { chosenOnboarding: 'planning' }
        })
        setOnboardingStage(3)
        setIsTracking(false)
    }

    function handleNextDietaryPreferencesClick() {
        setOnboardingStage(4)
    }

    function handleCalculateClick() {
        setOnboardingStage(5)
    }

    function handleSkipClick() {
        setSkipped(true)
        setOnboardingStage(5)
    }


    function handleEndOfOnboarding() {
        props.setIsNewlyWrittenUser(false)
    }
    
    function handleEndOfOnboardingPlanning(){
        history.push("/planning");
        props.setIsNewlyWrittenUser(false)

    }

    if (onboardingStage === 0) {
        return (
            <div style={{ padding: '20px 10px', maxWidth: '400px', width: 'calc(100vw - 20px)' }}>
                <div className="onboarding-wizard-title-first" >Welcome to</div>
                <div className="onboarding-wizard-title-first-next">BITE<span style={{ color: '#2D7933' }}>TRACKER</span>!</div>

                <div className="onboarding-wizard-upsell-row">
                    <div className="onboarding-wizard-upsell-row-icon">
                        <img className="onboarding-wizard-upsell-row-icon-inner" alt="Group" src={freeIcon} />
                    </div>
                    <div className="onboarding-wizard-upsell-row-text-first">
                        <div>We are <b>100% free</b> - with no hidden fees & no subscriptions.
                        </div>

                    </div>
                </div>
                <div className="onboarding-wizard-upsell-row">
                    <div className="onboarding-wizard-upsell-row-icon">
                        <img className="onboarding-wizard-upsell-row-icon-inner" alt="Group" src={saveTimeIcon} />
                    </div>
                    <div className="onboarding-wizard-upsell-row-text-first">
                        We want to help you save time & achieve your goals - at <b>your pace.</b>
                    </div>

                </div>

                <div className="onboarding-wizard-upsell-row">
                    <div className="onboarding-wizard-upsell-row-icon">
                        <img className="onboarding-wizard-upsell-row-icon-inner" alt="Group" src={ideasIcon} />
                    </div>
                    <div className="onboarding-wizard-upsell-row-text-first">
                        It's still early days for us so keep an eye for new features. If you have feedback, <b>reach out to us!</b>
                    </div>

                </div>
                <div style={{ margin: '30px auto 10px auto', maxWidth: '295px', textAlign: 'center' }}>
                    <div style={{ textAlign: 'left' }}>Cheers,</div>
                    <div style={{ paddingTop: '6px', fontFamily: 'Montserrat-SemiBold', textAlign: 'left' }}>The BiteTracker Team</div>
                </div>

                <div style={{ textAlign: 'center' }}>

                    <img style={{ margin: '10px auto -5px auto', width: '300px' }} className="onboarding-wizard-upsell-row-image" alt="Group" src="3857131-1.png" />
                </div>



                <div style={{ textAlign: 'center' }}>
                    <button onClick={handleFirstClick} variant="outlined" className="calendar-add-menu-meal-button">Get Started</button>
                </div>
            </div >
        )
    }

    if (onboardingStage === 1) {
        return (
            <div style={{ padding: '20px 10px', maxWidth: '400px', width: 'calc(100vw - 20px)' }}>
                <div className="onboarding-wizard-title" >With our app you will be able to:</div>

                <div className="onboarding-wizard-upsell-row">
                    <div className="onboarding-wizard-upsell-row-inner">
                        <img className="onboarding-wizard-upsell-row-image" alt="Recipe Book"  key="1" src="group-18400.png" />
                    </div>
                    <div className="onboarding-wizard-upsell-row-text">
                        <div className="onboarding-wizard-upsell-row-number">1</div>
                        <div><b>Track the calories and macros</b> of foods via search, barcode scanning or with AI Tracking.
                        </div>

                    </div>
                </div>

                <div className="onboarding-wizard-upsell-row">
                    <div className="onboarding-wizard-upsell-row-text" style={{ marginLeft: '4px', marginRight: '10px' }}>
                        <div className="onboarding-wizard-upsell-row-number">2</div>
                        <div><b>Plan recipes </b> to cook & eat using our curated recipe book or by adding web/your own recipes
                        </div>

                    </div>
                    <div className="onboarding-wizard-upsell-row-inner">
                        <img style={{ marginTop: '10px', height: '80px' }} className="onboarding-wizard-upsell-row-image"  key="2" alt="Calendar" src={calendarOnboarding} />
                    </div>

                </div>

                <div className="onboarding-wizard-upsell-row" style={{ marginTop: '-8px' }}>
                    <div className="onboarding-wizard-upsell-row-inner">
                        <img className="onboarding-wizard-upsell-row-image" alt="takeAway" key="3" src={takeAwayOnboarding} />
                    </div>
                    <div className="onboarding-wizard-upsell-row-text">
                        <div className="onboarding-wizard-upsell-row-number">3</div>
                        <div><b>Order recipe ingredients </b> of recipes planned directly via Instacart, WholeFoods or AmazonFresh.
                        </div>

                    </div>
                </div>
                <div style={{ textAlign: 'center' }}>
                    <button onClick={handleNextClick} variant="outlined" className="calendar-add-menu-meal-button">Next</button>
                </div>
            </div>
        )
    }

    if (onboardingStage === 2) {
        return (
            <div style={{ padding: '20px 10px', maxWidth: '400px', width: 'calc(100vw - 20px)' }}>
                <div className="onboarding-wizard-title" >Where do you want to get started?</div>

                <Button onClick={handlePlanningOptionClick} className="onboarding-wizard-button" variant="outlined">

                    <div className="onboarding-wizard-button-left">
                        <div className="onboarding-wizard-button-title">Meal Planning</div>
                        <img className="onboarding-wizard-upsell-row-image" style={{ marginTop: '8px' }} alt="Group" src="group-18442.png" />
                    </div>
                    <div className="onboarding-wizard-button-text">

                        <div className="onboarding-wizard-button-content">Plan the recipes you want to cook throughout the week and order ingredients via Instacart/Amazon.

                        </div>

                    </div>
                </Button>

                <Button onClick={handleTrackingOptionClick} className="onboarding-wizard-button" variant="outlined">

                    <div className="onboarding-wizard-button-left">
                        <div className="onboarding-wizard-button-title">Food Tracking</div>
                        <img className="onboarding-wizard-upsell-row-image" alt="Group" src="group-18400.png" />
                    </div>
                    <div className="onboarding-wizard-button-text">

                        <div className="onboarding-wizard-button-content">Track the calories and macros of foods via search, barcode scanning or with AI Tracking.
                        </div>

                    </div>
                </Button>

            </div>
        )
    }


    if (onboardingStage === 3) {
        return (
            <div>
                <div style={{ width: '270px', margin: ' 20px auto -10px auto', fontSize: '16px' }} className="onboarding-wizard-title" >
                    Tell us your <br></br><b style={{ color: '#2D7933' }}>dietary preferences</b>
                </div>

                <DietaryPreferences handleExitClick={handleNextDietaryPreferencesClick} skip={handleNextDietaryPreferencesClick} />


            </div>
        )
    }

    if (onboardingStage === 4) {
        return (
            <div>
                <div style={{ width:'300px', maxWidth:'calc(100vw - 60px)',padding:'0px 30px', margin: ' 25px auto -30px auto', fontSize: '16px' }} className="onboarding-wizard-title" >
                    Let's calculate your <br></br><b style={{ color: '#2D7933' }}>calorie needs</b>
                    </div>

                <CalorieCalculator handleExitClick={handleCalculateClick} skip={handleSkipClick} />


            </div>
        )
    }
    else {
        return (
            <div style={{ padding: '20px', textAlign: 'center' }}>

                <CalorieBox skipped={skipped} />
                <div style={{ height: '12px' }}></div>
                <div className="onboarding-wizard-form-submit-button">
                    {skipped && <>
                        <div onClick={isTracking ?handleEndOfOnboarding:handleEndOfOnboardingPlanning} style={{ color: '#999999', fontSize: '14px', textAlign: 'center', fontFamily: 'Montserrat-SemiBold' }}><span onClick={props.skip}>SKIP</span></div>
                        <div style={{ backgroundColor: '#259B36', opacity: '0.5', margin: '0px 0px 0px 15px' }} className="calorie-chart-green-dot" />
                        <div style={{ backgroundColor: '#259B36', opacity: '0.65', margin: '0px 3px 0px 3px' }} className="calorie-chart-green-dot" />
                        <div style={{ backgroundColor: '#259B36', opacity: '0.8', margin: '0px 10px 0px 0px' }} className="calorie-chart-green-dot" />
                    </>}
                    {isTracking ? <button onClick={handleEndOfOnboarding} className='calendar-add-menu-meal-button'>Start tracking</button>:
                    <button onClick={handleEndOfOnboardingPlanning} className='calendar-add-menu-meal-button'>Start planning</button>
                    }
                </div>

            </div>
        )
    }




}



export default OnboardingWizard;